import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from 'react-validation/build/select';
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Link } from "react-router-dom";

import AuthService from "../services/auth.service";

const required = value => {
  if (!value.replaceAll(" ", "")) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value.replaceAll(" ", ""),)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vcellphone = value => {
  const unpermittedSecondNumbers = ['0', '1', '2', '3', '4', '5', '9']
  if (!/^[0-9]*$/.test(value.replaceAll(" ", "")) ||
      value.replaceAll(" ", "").length !== 10 ||
      value.replaceAll(" ", "").split('')[0] !== '0' ||
      unpermittedSecondNumbers.includes(value.replaceAll(" ", "").split('')[1])
      ) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not the correct cellphone number.
      </div>
    );
  }
};

const vFirstName = value => {
  if (value.replaceAll(" ", "").length < 3 || value.replaceAll(" ", "").length > 20 || !/^[A-Za-z-]*$/.test(value.replaceAll(" ", ""))) {
    return (
      <div className="alert alert-danger" role="alert">
        The value entered is invalid.
      </div>
    );
  }
};


const vpassword = value => {
  if (value.length < 8 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 8 and 40 characters.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeCellNumber = this.onChangeCellNumber.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeTerms = this.onChangeTerms.bind(this);

    this.state = {
      referral_key: this?.props?.location?.search?.split('=')[1] || null,
      cellphone: "",
      first_name: "",
      last_name: "",
      role: "rider",
      gender: "",
      email: "",
      password: "",
      terms: "",
      successful: false,
      loading: false,
      message: ""
    };
  }

  onChangeCellNumber(e) {
    this.setState({
      cellphone: e.target.value
    });
  }

  onChangeTerms(e) {
    this.setState({
      terms: e.target.value
    });
  }

  onChangeFirstName(e) {
    this.setState({
      first_name: e.target.value
    });
  }

  onChangeLastName(e) {
    this.setState({
      last_name: e.target.value
    });
  }

  onChangeGender(e) {
    let gender = e.target.value;
    this.setState({
      gender: gender
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.cellphone.replaceAll(" ", ""),
        this.state.first_name.replaceAll(" ", ""),
        this.state.last_name.replaceAll(" ", ""),
        this.state.role,
        this.state.gender,
        this.state.email.replaceAll(" ", ""),
        this.state.password,
        this.state.referral_key
      ).then(
        response => {

          // AuthService.login(this.state.cellphone, this.state.password).then(
            // res => {

              this.props.history.push({
                pathname: "/auth/verify-number",
                state: { cellphone_number: this.state.cellphone_number,
                         message: 'Account succesfully registered. Verify your cellphone number.',
                         successful: true
                       }
              });

              window.location.reload();

            }, error => {
              this.setState({
                message: error.message,
                loading: false,
                successful: false
            });
            // });

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            loading: false,
            message: resMessage
          });
        }
      );
    }else {
      this.setState({
        successful: false,
        loading: false,
        message: "There were errors, fix to continue."
      });
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
            {/* <img
              src={logo}
              alt="logo"
              className="profile-img-card"
            /> */}
               <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">
                  Register your new account
              </h2>

          <Form
            onSubmit={this.handleRegister}
            ref={c => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="cellphone">Cell number</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="cellphone"
                    value={this.state.cellphone}
                    onChange={this.onChangeCellNumber}
                    validations={[required, vcellphone]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="first_name">Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.onChangeFirstName}
                    validations={[required, vFirstName]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="last_name">Surname</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="last_name"
                    value={this.state.last_name}
                    onChange={this.onChangeLastName}
                    validations={[required, vFirstName]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="gender">Gender
                    <svg className="mat-icon-grey actionBtnIcon" data-tip="This is to help match you to the most suitable drivers/riders" style={{top: 0}} viewBox="0 0 24 24">
                      <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </label>

                  <Select name='gender' validations={[required]}
                    className="form-control"
                    value={this.state.gender}
                    onChange={this.onChangeGender}
                  >
                    <option value=''>Choose your gender</option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                  </Select>
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    validations={[required, email]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>

                {/* <div className="form-group">
                  <label htmlFor="terms">Accept <Link to={"/about-us/terms-and-conditions"} className="brand-link">Terms & Conditions.</Link></label>
                  <Input
                    type="checkbox"
                    className="form-control"
                    name="terms"
                    id="terms"
                    value={this.state.terms}
                    onChange={this.onChangeTerms}
                    validations={[required]}
                  />
                </div> */}

                <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                    disabled={this.state.loading}
                  >
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Sign Up</span>
                  </button>
                  {/* <button className="btn btn-primary btn-block">Sign Up</button> */}
                  <p>Already registered? <Link to={"/auth/login"} className="brand-link">Login.</Link>
                  </p>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
import axios from 'axios';
import authHeader from './auth-header';
import AuthService from "../services/auth.service";

const API_URL = process.env.REACT_APP_API_URL;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'users');
  }

  getUserBoard() {
    return axios.get(API_URL + `users/${AuthService.getCurrentUser().user.id}`, { headers: authHeader() });
  }

  getDriverBoard() {
    return axios.get(API_URL + 'driver', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  update(userId, user) {
    return axios.patch(API_URL + "users/" + userId, {
      user
    }, { headers: authHeader() });
  }
}

export default new UserService();
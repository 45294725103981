import React, { Component } from "react";

export default class CancelPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="col-md-12 text-center">

          <img src={"https://cdn.blablacar.com/kairos/assets/build/images/astronaut-79c2cfcb1a66f7c9afc1bbc50f0037fd.svg"} width="200" height="300" alt="Not found" />
          <h2 className="mt-3 text-3xl font-extrabold text-gray-900">
              You have succesfully cancelled your order. You can book again on the app should there be any changes.  
          </h2>
      </div>
    );
  }
}
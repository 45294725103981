import React, { Component } from "react";
import { Link } from "react-router-dom";
import AuthService from "../services/auth.service";

export default class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      token: this.props.match.params.confirmation_token,
      loading: true,
      successful: false,
      message: ""
    };
  }

  componentDidMount(){
    AuthService.verifyEmail(this.state.token).then((res) => {
      this.setState({
        message: res.data.message,
        successful: true,
        loading: false
      });
    })
  }

  render() {
    return (
      <div className="col-md-12">
        <br/><br/>
        {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-warning"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                  <Link to={"/auth/login"} className="brand-link"> Login.</Link>
                </div>
              </div>
        )}
      </div>
    );
  }
}
import axios from "axios";
import authHeader from './auth-header';
require('dotenv').config()

const API_URL = process.env.REACT_APP_API_URL;

class VehicleService {
  create(user_id, make, model, number_plate, color, image_url) {
    return axios
      .post(API_URL + "vehicles", {
        user_id,
        make,
        model,
        number_plate,
        color,
        image_url
      }, { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  index() {
    return axios.get(API_URL + "vehicles", {headers: authHeader()}).then(response => {
        return response.data;
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new VehicleService();
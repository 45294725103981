import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from 'react-validation/build/select';
import MultiSelect from "react-multi-select-component";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import TripService from "../services/trip.service";
import TownService from "../services/town.service";
import ProvinceService from "../services/province.service";
import VehicleService from "../services/vehicle.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from 'firebase/app';
import 'firebase/storage';
import FileUploader from "react-firebase-file-uploader";
var dateFormat = require("dateformat");

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const awesomeDate = value => {

  if(value) {
    let now = dateFormat(new Date(), "yyyy-mm-dd HH:MM");
    let inputtedDate = dateFormat(new Date(value), "yyyy-mm-dd HH:MM");

    if (inputtedDate < now) {
      return (
        <div className="alert alert-danger" role="alert">
          Date/Time is too close. {inputtedDate} {now}
        </div>
      );
    }
  }else{

    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );

  }
};

export default class Trip extends Component {
    constructor(props) {
        super(props);
        this.handleCreateTrip = this.handleCreateTrip.bind(this);
        this.handleCreateVehicle = this.handleCreateVehicle.bind(this);
        this.onChangeSeats = this.onChangeSeats.bind(this);
        this.onChangeDeparture = this.onChangeDeparture.bind(this);
        this.onChangeDestination = this.onChangeDestination.bind(this);
        this.onChangeDepartureProvince = this.onChangeDepartureProvince.bind(this);
        this.onChangeDestinationProvince = this.onChangeDestinationProvince.bind(this);
        this.onChangePrices = this.onChangePrices.bind(this);
        this.onChangeVehicleId = this.onChangeVehicleId.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);

        //Create Vehicle Components
        this.addingVehicle = this.addingVehicle.bind(this);
        this.onChangeMake = this.onChangeMake.bind(this);
        this.onChangeModel = this.onChangeModel.bind(this);
        this.onChangePlateNumber = this.onChangePlateNumber.bind(this);
        this.onChangeColor = this.onChangeColor.bind(this);
        this.onChangeImageUrl = this.onChangeImageUrl.bind(this);

        this.state = {
            provinces: [],
            towns:  [],
            vehicles: [],
            priceList: [],
            user: AuthService.getCurrentUser().user.id,
            seats: 0,
            departure: "",
            destination: "",
            departure_province_id: "",
            destination_province_id: "",
            price_ids: [],
            vehicle_id: "",
            date: "",
            time: "",
            successful: false,
            message: null,
            isAddingVehicle: false,
            destination_towns: [],
            destination_provinces: [],

            // Vehicle
            make: "",
            model: "",
            number_plate: "",
            color: "",
            image_url: "",
            avatar: "",
            isUploading: false,
            progress: 0

        };
    }


    addingVehicle(){
      this.setState({
        isAddingVehicle: !this.state.isAddingVehicle,
    });
    }



    //Create Trip Handlers
    onChangeSeats(e) {
        this.setState({
            seats: e.target.value
        });
    }

    onChangeDeparture(e) {
      this.setState({
        departure: e.target.value,
      });
    }

    onChangeDestination(e) {
        this.setState({
            destination: e.target.value
        });
    }

    onChangeDepartureProvince(e) {
      let departureProvinceId = e.target.value

      this.setState({
        departure: '',
        destination_provinces: [],
        destination: '',
        destination_province_id: '',
        price_ids: []
      })

      TownService.index().then(response => {
        this.setState({towns: response.filter(town => {
          return town.province_id == departureProvinceId
        })});
      });

      let destination_provinces = this.state.provinces.filter(province =>{
       return province.id.toString() !== departureProvinceId.toString()
      });

      this.setState({
        departure_province_id: departureProvinceId,
        destination_provinces: destination_provinces
      });
    }

    onChangeDestinationProvince(e) {
      let destinationProvinceId = e.target.value

      this.setState({
        price_ids: []
      })

      TownService.index().then(response => {
        this.setState({destination_towns: response.filter(town => {
          return town.province_id == destinationProvinceId
        })});
      });

      TripService.priceList(this.state.departure_province_id, destinationProvinceId).then(response => {
        this.setState({priceList: response.map(item => {
          return {label: `${item.price_type.name }(R${item.value})`, value: item.id}
        })});
      });

      this.setState({
        destination_province_id: destinationProvinceId
      });
    }

    onChangePrices(e) {
        this.setState({
            price_ids: e
        });
    }


    onChangeVehicleId(e) {
        let vehicle_id = e.target.value;
        this.setState({
            vehicle_id: vehicle_id
        });
    }

    onChangeDate(e) {

      let inputtedDate = dateFormat(new Date(e.target.value), "yyyy-mm-dd HH:MM:ss");

      this.setState({
          date: dateFormat(inputtedDate, "yyyy-mm-dd"),
          time: dateFormat(inputtedDate, "HH:MM:ss")
      });
    }

//End Create Trip Handler

//Create Vehicle Handler
  onChangeMake(e) {
      this.setState({
          make: e.target.value
      });
  }

  onChangeModel(e) {
      this.setState({
          model: e.target.value
      });
  }

  onChangePlateNumber(e) {
      this.setState({
          number_plate: e.target.value
      });
  }

  onChangeColor(e) {
      this.setState({
          color: e.target.value
      });
  }

  onChangeImageUrl(e) {
      this.setState({
          image_url: e.target.value
      });
  }

  //End Create Vehicle Handler



  componentDidMount(){
    ProvinceService.index().then(response => {
      console.log(response)
      this.setState({provinces: response});
    });

    VehicleService.index().then(response => {
      this.setState({vehicles: response});
    });
  }



  //Create Vehicle Function
  handleCreateVehicle(e){

    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.vehicleForm.validateAll();

    if (this.vehicleBtn.context._errors.length === 3) {
      VehicleService.create(
        this.state.user,
        this.state.make,
        this.state.model,
        this.state.number_plate,
        this.state.color,
        this.state.image_url
      ).then(
        response => {
          this.setState({
            isAddingVehicle: false,
          });

          toast('Vehicle Succesfully Created.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

            //Get all Vehicles
            window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.errors) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }else {
      this.setState({
        successful: false,
        message: "There were errors, fix to continue."
      });
    }

  }


  handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ image_url: url }));
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  //End Create Vehicle Function

  //Create Trip Function
  handleCreateTrip(e) {


      e.preventDefault();

      this.setState({
        message: "",
        successful: false
      });



      this.form.validateAll();

          if (this.checkBtn.context._errors.length === 0 && this.state.price_ids.length > 0) {
            TripService.create(
              this.state.user,
              this.state.seats,
              this.state.departure.split(',')[0],
              this.state.destination,
              this.state.price_ids.map(item => {return item.value}),
              this.state.vehicle_id,
              this.state.date,
              this.state.time
            ).then(
              response => {
                this.setState({
                  message: "Trip Succesfully Created",
                  successful: true
                });
              },
              error => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.errors) ||
                  error.message ||
                  error.toString();

                this.setState({
                  successful: false,
                  message: resMessage
                });
              }
            );
          }else {
            this.setState({
              successful: false,
              message: "There were errors, fix to continue."
            });
          }
      }
  //Create Trip Function


 //Create Vehicle Function

  //End Create Vehicle Function

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
            <p className="h4 text-center py-4">Create a lift</p>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
              {/* Same as */}
              <ToastContainer />

               {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
          )}


          {!this.state.isAddingVehicle ?

          <div>


        {this.state.vehicles.length > 0 ?


            <div className="flex flex-col sm:flex-row sm-mx-12 flex-wrap items-center justify-end">
              <div className="">
                <button onClick={this.addingVehicle} className="btn btn-primary btn-block">Add Another Vehicle</button>
              </div>
            </div>

                :

            <div className="flex flex-col sm-mx-6 flex-wrap items-center justify-center">
              <p style={{color: 'orange', padding: '10px'}}>You do not have any vehicles, please add a vehicle to get started</p>
              <div className="">
                <button onClick={this.addingVehicle} className="btn btn-primary btn-block">Add Vehicle</button>
              </div>
            </div>
          }


          <Form
            onSubmit={this.handleCreateTrip}
            ref={v => {
              this.form = v;
            }}

          >

            {!this.state.successful && (
              <div>

                <div className="form-group">
                <label htmlFor="seats">Select Vehicle *</label>
                  <Select name='vehicle_id' validations={[required]}
                      className="form-control"
                      value={this.state.vehicle_id}
                      onChange={this.onChangeVehicleId}
                    >
                      <option value="">Select vehicle</option>
                      {this.state.vehicles.map((item, i) => {
                        return <option value={item.id} key={i}>{item.make} {item.model}</option>
                      })}
                    </Select>
                  </div>


                <div className="form-group">
                  <label htmlFor="seats">Seats to Share *</label>
                  <Input
                    type="number"
                    className="form-control"
                    name="seats"
                    placeholder="Number of Seats to Book"
                    value={this.state.seats}
                    onChange={this.onChangeSeats}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="departure_province">Departure Province *</label>
                  <Select name='departure_province' validations={[required]}
                    className="form-control"
                    value={this.state.departure_province_id}
                    onChange={this.onChangeDepartureProvince}
                  >

                  <option key="" value="">Select Departure Province</option>
                      {this.state.provinces.map((item, i) => {
                        return <option value={item.id} key={i}>{item.name}</option>
                      })}
                </Select>
                </div>

                {
                  this.state.departure_province_id ?
                  <div className="form-group">
                    <label htmlFor="departure">Departure *</label>
                    <Select name='departure' validations={[required]}
                      className="form-control"
                      value={this.state.departure}
                      onChange={this.onChangeDeparture}
                    >

                    <option key="" value="">Select Departure Town</option>
                        {this.state.towns.map((item, i) => {
                          return <option value={[item.id, item.province_id]} key={i}>{item.name}</option>
                        })}
                  </Select>
                  </div>
                  : ''

                }

                {
                  this.state.departure_province_id && this.state.departure ?
                  <div className="form-group">
                    <label htmlFor="destination_province_id">Destination Province *</label>
                    <Select name='destination_province_id' validations={[required]}
                      className="form-control"
                      value={this.state.destination_province_id}
                      onChange={this.onChangeDestinationProvince}
                    >

                    <option key="" value="">Select Destination Province</option>
                        {this.state.destination_provinces.map((item, i) => {
                          return <option value={item.id} key={i}>{item.name}</option>
                        })}
                  </Select>
                  </div>
                  : ''

                } 

                {
                  this.state.destination_province_id && this.state.departure ?
                    <div className="form-group">
                      <label htmlFor="destination">Destination *</label>
                      <Select name='destination' validations={[required]}
                        className="form-control"
                        value={this.state.destination}
                        onChange={this.onChangeDestination}
                      >

                        <option value="">Select Destination Town</option>
                        {this.state.destination_towns.map((item, i) => {
                            return <option value={item.id} key={i}>{item.name}</option>
                        })}

                      </Select>
                    </div>
                  : ''
                }

                { this.state.departure_province_id && this.state.destination_province_id ?
                  <div className="form-group">
                      <label htmlFor="price_ids">Price *</label>
                      <div class="flex gap-4 bg-red-100 p-4 rounded-md">
                          <div class="w-max">
                              <div class="h-10 w-10 flex rounded-full bg-gradient-to-b from-red-100 to-orange-300 text-orange-700">
                                <span class="material-icons material-icons-outlined m-auto" style={{fontSize: "20px"}}>gpp_bad</span>
                              </div>
                          </div>
                          <div class="space-y-1 text-sm">
                            <h6 class="font-medium text-orange-900">PLEASE NOTE!</h6>
                            <p class="text-orange-700 leading-tight">Liftsearch retains R50 of this amount per seat. <a className="font-medium text-green-600 dark:text-green-900 hover:underline" href="/subscription/driver"> Subscribe</a> to get a free pass and keep all amounts paid per seat all month/year.</p>
                          </div>
                      </div>



                      <MultiSelect
                          options={this.state.priceList}
                          value={this.state.price_ids}
                          onChange={this.onChangePrices}
                          labelledBy={"Select"}
                          validations={[required]}
                      />

                  </div> : ''
                  }



                <div className="form-group">
                  <label htmlFor="date">Date/Time of departure *</label>
                  <Input
                    type="datetime-local"
                    className="form-control"
                    name="date"
                    value={this.state.date}
                    onChange={this.onChangeDate}
                    validations={[required, awesomeDate]}
                  />

                </div>

                <div className="form-group">
                  <button className="btn btn-primary btn-block">Create trip</button>
                </div>
              </div>
            )}

            <CheckButton
              style={{ display: "none" }}
              ref={a => {
                this.checkBtn = a;
              }}
            />
          </Form>

          </div>

              :

          <div>


            <div className="flex flex-col sm:flex-row sm-mx-12 flex-wrap items-center justify-end">
              <div className="">
                <button onClick={this.addingVehicle} className="btn btn-primary btn-block">X</button>
              </div>
            </div>

          <Form
            onSubmit={this.handleCreateVehicle}
            ref={t => {
              this.vehicleForm = t;
            }}
          >
              <div>


                <div className="form-group">
                  <label htmlFor="vehicle_id">Add Vehicle</label>



                      <div className="border rounded bg-gray-300 px-3 py-3">

                        <div className="form-group">
                          <label htmlFor="make">Make</label>
                          <Input
                            type="text"
                            className="form-control"
                            name="make"
                            value={this.state.make}
                            onChange={this.onChangeMake}
                            validations={[required]}
                          />
                        </div>


                        <div className="form-group">
                          <label htmlFor="model">Model</label>
                          <Input
                            name='model'
                            validations={[required]}
                            type="text"
                            className="form-control"
                            value={this.state.model}
                            onChange={this.onChangeModel}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="number_plate">Plate number</label>
                          <Input name='number_plate'
                            validations={[required]}
                            className="form-control"
                            type="text"
                            value={this.state.number_plate}
                            onChange={this.onChangePlateNumber}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="color">Color</label>
                          <Input name='color'
                            validations={[required]}
                            className="form-control"
                            value={this.state.color}
                            type="text"
                            onChange={this.onChangeColor}
                          />
                        </div>

                        <div className="form-group">
                          <label style={{backgroundColor: '#00AFF5', color: 'white', padding: 10, borderRadius: 4, cursor: 'pointer'}}>
                          {this.state.isUploading ? <p>Uploading {this.state.progress}</p> : this.state.progress === 100 ? <p>Uploaded succesfully</p> : <p>Select your vehice image</p>}
                          <FileUploader
                            hidden
                            accept="image/*"
                            name="avatar"
                            randomizeFilename
                            storageRef={firebase.storage().ref("images")}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            validations={[required]}
                            onProgress={this.handleProgress} />
                            </label>
                          {/* <label htmlFor="image_url">Image Url</label>
                          <Input name='image_url'
                            className="form-control"
                            value={this.state.image_url}
                            type="text"
                            onChange={this.onChangeImageUrl}
                            validations={[required]}
                          />  */}
                        </div>

                      </div>


                </div>



                <div className="form-group">
                  <button className="btn btn-primary btn-block" disabled={this.state.isUploading}>Add Vehicle</button>
                </div>
              </div>

            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.vehicleBtn = c;
              }}
            />
          </Form>


          </div>


            }


        </div>
      </div>
    );
  }
}
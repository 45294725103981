import axios from "axios";
import authHeader from './auth-header';
// import AuthService from "../services/auth.service";

const API_URL = process.env.REACT_APP_API_URL;

class OrderService {
  create(user_id, trip_id, status, price_id, seats) {
    return axios
      .post(API_URL + "orders", { order: { user_id, trip_id, status, price_id, seats}
      }, {headers: authHeader()})
      .then(response => {
        return response.data;
      });
  }

  updatePaymentStatus(order_id) {
    return axios
      .patch(API_URL + "orders/" + order_id, { order: { status: 1, driver_status: 1 }
      }, {headers: authHeader()})
      .then(response => {
        return response.data;
      });
  }

//   search(departure, destination, date, seats) {
//     return axios.get(API_URL + "trips", {params: {departure, destination, date, seats}});
//   }

//   my_trips(){
//     return axios.get(API_URL + "trips/user/" + AuthService.getCurrentUser().user.id, { headers: authHeader() });
//   }

//   find(trip_id){
//     return axios.get(API_URL + "trips/" + trip_id, {});
//   }

//   priceList() {
//     return axios.get(API_URL + "prices", {}).then(response => {
//         return response.data;
//     });
//   }
}

export default new OrderService();
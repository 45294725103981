import axios from "axios";
require('dotenv').config()

const API_URL = process.env.REACT_APP_API_URL;

class ProvinceService {
  index() {
    return axios.get(API_URL + "provinces", {}).then(response => {
        return response.data;
    });
  }
}

export default new ProvinceService();
import axios from "axios";
import authHeader from './auth-header';
import AuthService from "../services/auth.service";

const API_URL = process.env.REACT_APP_API_URL;

class TripService {
  create(user_id, seats, departure, destination, price_ids, vehicle_id, date, time) {
    return axios
      .post(API_URL + "trips", {
        user_id, 
        seats, 
        departure, 
        destination, 
        price_ids, 
        vehicle_id, 
        date, 
        time,
        departure_id: departure,
        destination_id: destination
      }, {headers: authHeader()})
      .then(response => {
        return response.data;
      });
  }

  search(departure, destination, date, seats) {
    return axios.get(API_URL + "trips", {params: {
      departure, 
      destination, 
      date, 
      seats
    }});
  }

  my_trips(){
    return axios.get(API_URL + "trips/user/" + AuthService.getCurrentUser().user.id, { headers: authHeader() });
  }

  find(trip_id){
    return axios.get(API_URL + "trips/" + trip_id, {});
  }

  priceList(departure_province_id, destination_province_id) {
    return axios.get(API_URL + `prices?departure_id=${departure_province_id}&destination_id=${destination_province_id}`, {}).then(response => {
        return response.data;
    });
  }
}

export default new TripService();
import axios from "axios";
require('dotenv').config()

const API_URL = process.env.REACT_APP_API_URL;

class TownService {
  index() {
    return axios.get(API_URL + "towns", {}).then(response => {
        return response.data;
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new TownService();
import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Link } from "react-router-dom";
import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeCellNumber = this.onChangeCellNumber.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      cellphone: "",
      password: "",
      loading: false,
      redirectTo: this.props.history.location.search?.slice(1)?.split('&')[0]?.split('=')[1] || '/edit-profile',
      pushedState: this.props.history.location.search?.slice(1)?.split('&')[1]?.split('=')[1] || '{}',
      successful: this.props.location?.state?.successful,
      message: this.props.location?.state?.message
    };
  }

  onChangeCellNumber(e) {
    this.setState({
      cellphone: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.cellphone, this.state.password).then(
        () => {
          this.props.history.push({
            pathname: this.state.redirectTo,
            state: JSON.parse(this.state.pushedState)
          }); 
          window.location.reload();
        },
        error => {

          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          
          this.setState({
            loading: false,
            successful: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
        {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
          )}
          <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">
                Sign in to your account
              </h2>
          <Form
            onSubmit={this.handleLogin}
            ref={c => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="cellphone">Cell number/Email</label>
              <Input
                type="text"
                className="form-control"
                name="cellphone"
                value={this.state.cellphone}
                onChange={this.onChangeCellNumber}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
              <p>Not registered yet? <Link to={"/auth/sign-up"} className="brand-link">
                  Register.
              </Link>
              </p>
              <Link to={"/auth/reset-request"} className="brand-link">Forgot Password?</Link>
            </div>
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class ResetRequest extends Component {
  constructor(props) {
    super(props);
    this.handleVerify = this.handleVerify.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);

    this.state = {
      email: "",
      loading: false,
      message: "",
      successful: false
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  handleVerify(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.ResetRequest(this.state.email).then(
        res => {

          this.setState({
            loading: false,
            successful: true,
            message: res.message
          });

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            successful: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          {this.state.successful ? (
                <div className="form-group">
                  <div
                    role="alert"
                  >
                    <h1>{this.state.message}</h1>

                    <p>If we find {this.state.email} in our system, we will send you an email with a link to reset your password.</p>
                    <br />
                    <p>If you don't receive the email, check your spam folder or contact us.</p>
                  </div>
                </div>
            ) :
            <div>
          <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">
                Forgot your password?
              </h2>
          <Form
            onSubmit={this.handleVerify}
            ref={c => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="email">Enter your email address and we’ll send you instructions on how to reset your password.</label>
              <Input
                type="text"
                className="form-control"
                name="email"
                value={this.state.email}
                onChange={this.onChangeEmail}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Send reset instructions</span>
              </button>
            </div>
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
          </div>
          }
        </div>
      </div>
    );
  }
}
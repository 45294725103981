import React, { Component } from "react";
import { TripCard } from '@blablacar/ui-library/build/tripCard';
import { WarningIcon } from '@blablacar/ui-library/build/icon/warningIcon';
import { Loader } from '@blablacar/ui-library/build/loader'
import { ContentDivider } from "@blablacar/ui-library/build/divider/contentDivider";
import TripService from "../services/trip.service"

export default class Search extends Component {
  constructor(props) {
    super(props);
    // let whereState = this?.props?.location?.search?.split('?')[1]?.split('=')[1]
    // console.log(whereState.replace(/%20/g, "'").replace(/%27/g, "'"))

    // let myState = JSON.parse(whereState.replace(/%20/g, "'").replace(/%27/g, "'"))

    this.state = {
        departure: this.props.location.state.departure,
        destination: this.props.location.state.destination,
        date: this.props.location.state.date,
        seats: this.props.location.state.seats,
        results: [],
        related: [],
        loading: true
    }
  }

  componentDidMount(){
    TripService.search(this.state.departure, this.state.destination, this.state.date, this.state.seats).then(response => {
        this.setState({loading: false});
        this.setState({results: response.data.results,
                       related: response.data.related });
    });
  }

  render() {

    return (
      <div className="container mt-3 bg-gray-200">
        <header className="bg-white h-full  border-b-4 border-green-500 rounded-lg p-3 mb-2 mt-2  overflow-hidden shadow-lg">
              Search results
        </header>
        <ContentDivider />
        {this.state.loading ? <Loader /> :
        this.state.results.length > 0 ?  this.state.results.map((item, i) => {
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            let date = new Date(item.date).toLocaleDateString("en-US", options);
            let time = item.time.split('T')[1].substring(0, 5);
            return (

              <TripCard style={{marginTop: '8px'}} key={i} aria-label={`Pick-up point: ${item.departure.name}, Drop-off point: ${item.destination.name}, Departure time: 09:00, warning`} href={`${item.id}?seats=${this.state.seats}`} itinerary={[{
                mainLabel: item.departure.name,
                subLabel: item.departure.province_id,
                isoDate: date,
              }, {
                mainLabel: item.destination.name,
                subLabel: item.destination.province_id,
              }]} price={`R${item.prices[item.prices.length - 1].value}`} flags={{
                ladiesOnly: true,
                aloneInTheBack: true,
                maxTwo: true,
                autoApproval: true
              }} titles={{
                ladiesOnly: 'Ladies Only',
                aloneInTheBack: 'Alone in the back',
                maxTwo: 'Max 2 in the back',
                autoApproval: 'Auto approval'
              }} metaUrl="Meta URL" statusInformation={{
                icon: <WarningIcon />,
                text: 'Warning',
                highlighted: false
              }} badge="The Closest" title={`${date}, ${time}`} driver={{
                avatarUrl: item?.user?.profile_pic_url || '//ssl.gstatic.com/accounts/ui/avatar_2x.png',
                firstName: item?.user?.first_name,
                rating: '4.9'
              }} />

            )

        }) :
        <h1 style={{color: 'red', fontSize: '25px'}}>No results found for this search</h1>
        }
        <br/>
        <header className="bg-white h-full  border-b-4 border-green-500 rounded-lg p-3 mb-2 mt-2  overflow-hidden shadow-lg">
              Related results
        </header>
        <ContentDivider />

        {this.state.loading ? <Loader /> :
        this.state.related.length > 0 ?  this.state.related.map((item, i) => {
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            let date = new Date(item.date).toLocaleDateString("en-US", options);
            let time = item.time.split('T')[1].substring(0, 5);
            return (

              <TripCard aria-label={`Pick-up point: ${item.departure.name}, Drop-off point: ${item.destination.name}, Departure time: 09:00, warning`} key={i} href={`${item.id}?seats=${this.state.seats}`} itinerary={[{
                mainLabel: item.departure.name,
                subLabel: item.departure.province_id,
                isoDate: date,
              }, {
                mainLabel: item.destination.name,
                subLabel: item.destination.province_id,
              }]} price={`R${item.prices[item.prices.length - 1].value}`} flags={{
                ladiesOnly: true,
                aloneInTheBack: true,
                maxTwo: true,
                autoApproval: true
              }} titles={{
                ladiesOnly: 'Ladies Only',
                aloneInTheBack: 'Alone in the back',
                maxTwo: 'Max 2 in the back',
                autoApproval: 'Auto approval'
              }} metaUrl="Meta URL" statusInformation={{
                icon: <WarningIcon />,
                text: 'Warning',
                highlighted: false
              }} badge="The Closest" title={`${date}, ${time}`} driver={{
                avatarUrl: item?.user?.profile_pic_url || '//ssl.gstatic.com/accounts/ui/avatar_2x.png',
                firstName: item?.user?.first_name,
                rating: '4.9'
              }} />

            )

        }) :
        <h1 style={{color: 'red', fontSize: '25px'}}>No related found for this search</h1>
        }
      </div>
    );
  }
}
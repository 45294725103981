import { AutoComplete } from '@blablacar/ui-library/build/autoComplete'
import React, { useState } from 'react'
import { ItemStatus } from '@blablacar/ui-library/build/_internals/item'
import { Loader, LoaderLayoutMode } from '@blablacar/ui-library/build/loader'
require('dotenv').config()

const API_URL = process.env.REACT_APP_API_URL;
let towns = [];

const places = () => fetch(API_URL + "towns")
                     .then(response => response.json())
                     .then(data => data.map(item => {
                        return towns.push({
                        id: item.id,
                        label: item.name,
                        province: item.province_id
                      })
                    }));

places();

export const AutoCompleteExample = ({
  searchForItemsDelay = 0,
  renderEmptySearch = [],
  onSelect,
  inputAddon,
  error,
  defaultValue,
}) => {
  const [items, setItems] = useState([])
  const [isSearching, setSearching] = useState(false)

  const searchForItems = (query) => {
    setSearching(true)
    setTimeout(() => {
      setItems(towns.filter(place => place.label.toLowerCase().startsWith(query.trim().toLowerCase())))
      setSearching(false)
    }, searchForItemsDelay)
  }

  return (
    <AutoComplete
      name="city"
      placeholder="Search town here"
      // indicates whether it should display a loading state
      isSearching={isSearching}
      // The default query
      defaultValue={defaultValue}
      // define if searchForItems is called on mount.
      // If a default value is present it will search for it.
      searchOnMount
      searchForItems={searchForItems} // method to trigger a search
      items={items} // Results of the search
      busyTimeout={500} // milliseconds before showing a loading state
      // custom loading display
      renderBusy={() => <Loader size={36} layoutMode={LoaderLayoutMode.INLINE} />}
      // allow to display default results when there is no results.
      renderEmptySearch={renderEmptySearch}
      // method called when an item is selected
      onSelect={onSelect}
      // Format the autocomplete selected value
      getItemValue={item => item.id}
      // Format the autocomplete display based on the selected item
      renderQuery={item => item.label}
      // An error message to display
      error={error}
      // max number of results that are display
      maxItems={5}
      // number of charachers required to trigger a search
      searchForItemsMinChars={3}
      // Display the status of the selected item, it can be loading or check
      selectedItemStatus={ItemStatus.DEFAULT}
      // Display a given icon in the input field
      inputAddon={inputAddon}
    />
  )
}
import React, { Component } from "react";
import { SearchForm } from '@blablacar/ui-library/build/searchForm'
import { AutoCompleteExample } from './autocomplete'
import Faq from 'react-faq-component';
import { Link } from "react-router-dom";
import driver from '../driver.png';
import Review from './misc/review';

import './home.css';

class Home extends Component {

  componentDidMount(){
  }

  render() {
    const data = {
      rows: [
        {
          title: "How do I book a ride?",
          content: "From our mobile or web app, search by your departure, destination, date and number of seats(Be sure to use the nearest major town to your village). Scroll through hundreds of your search results and pick the most suitable one, click book and pay your deposit to reserve your seat."
        },
        {
          title: "How do I offer a ride?",
          content: "From our mobile or web app, click 'Offer a ride', complete the ride details form then wait for the booking notifications as riders book your way in to the cheapest drive you will ever make."
        },
        {
          title: "How do I send a parcel?",
          content: "Click on our sister company <a class='brand-link' href='https://parcelin.co.za'>Parcelin</a> to start sending parcels same day, overnight or next Friday between Limpopo and Gauteng cheaper than any alternative."
        },
        {
          title: "Is the booking fee refundable?",
          content: "Yes, the booking fee is fully refundable instantly. Click on support to chat to one of our friendliest agents to initiate the refund process."
        },
        {
          title: "Which routes do you cover?",
          content: "We cover Limpopo, Mpumalanga and Eastern Cape with Gauteng being the primary departure and destination point. We are adding more provinces systematically."
        },
        {
          title: "Is it safe?",
          content: "Yes, every profile offering or requesting a lift on Liftsearch is manually validated(We are busy automating this process)."
        },
        {
          title: "Is it Reliable?",
          content: "Yes, every booking on Liftsearch has a booking confirmation, as a driver you can see the paid bookings and non paid from your lifts on the mobile app. Drivers notify us in time if there are changes so we may manually transfer you to another ride."
        }]
    }

    const styles = {
      bgColor: '#edf2f7',
      rowTitleColor: "#044752",
      rowTitleTextSize: "20px",
      rowContentColor: '#6B7280',
      rowContentTextSize: "15px",
      arrowColor: "#044752",
    };  

    return (
      <main className="mt-16 flex flex-col items-center">
      <div className="w-screen mt-1 mb-6">
        <div className="container mx-auto w-full flex justify-center items-center p-2 md:p-0" id="search">
        <SearchForm
          onSubmit={(formValues) => {
            this.props.history.push({
              pathname: '/trips/search',
              state: {
                        departure:  formValues?.AUTOCOMPLETE_FROM?.item?.label || '',
                        destination: formValues?.AUTOCOMPLETE_TO?.item?.label || '',
                        seats: formValues?.STEPPER,
                        date: formValues?.DATEPICKER
                    }
            })
          }}
          initialFrom=""
          initialTo=""
          disabledFrom={false}
          disabledTo={false}
          autocompleteToPlaceholder="Where to?"
          autocompleteFromPlaceholder="Where from?"
          renderAutocompleteFrom={props => <AutoCompleteExample {...props} embeddedInSearchForm />}
          renderAutocompleteTo={props => <AutoCompleteExample {...props} embeddedInSearchForm />}
          datepickerProps={{
            defaultValue: new Date().toISOString(),
            format: value => new Date(value).toLocaleDateString(),
          }}
          stepperProps={{
            defaultValue: 1,
            min: 1,
            max: 8,
            title: 'Choose your number of seats',
            increaseLabel: 'Increase the number of seats by 1',
            decreaseLabel: 'Decrease the number of seats by 1',
            format: value => `${value} seat(s)`,
            confirmLabel: 'Submit',
          }}
        />
        </div>
      </div>
      <div>
        <div className="bg-gray-100 lg:py-12 lg:flex lg:justify-center">
          <div className="bg-gray-100 lg:mx-12 lg:flex lg:max-w-5xl lg:shadow-lg lg:rounded-lg">
            <div className="lg:w-1/2 sm:w-full">
              <div
                className="h-64 bg-cover lg:rounded-lg lg:h-full md:w-full"
                style={{
                  backgroundImage: `url(${driver})`
                }}
              />
            </div>
            <div className="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2 sm:w-full md:w-full">
              <h2 className="text-3xl text-gray-800 font-bold">
                Where Do You Want{" "}
                <span className="text-green-500">To Drive To</span>?
              </h2>
              <p className="mt-4 text-gray-600">
                Every week, every month. To meet with a loved one, to
                a wedding, party or funeral. With a big family, or a big luggage. To
                Gauteng, Limpopo, Mpumalanga or Eastern Cape, save petrol and tollgate costs with Liftsearch.
              </p>
              <div className="mt-8">
              <Link to={"/trips/new"}
                  className="bg-blue-600 text-gray-100 px-5 py-3 font-semibold rounded"
                >
                  Offer a Lift
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-4 p-5">
        <div className="p-12 text-center max-w-12xl">
          <div className="md:text-3xl text-3xl font-bold">
            Go Literally <span className="text-green-500">Anywhere</span>. From <span className="text-green-500">Anywhere</span>.
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:-mx-2">
          <div className="mt-4 sm:w-1/3">
            <div className="bg-gray-100 h-full p-8 border-b-4 border-green-500 rounded-lg flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
              <div className="bg-gray-200 text-green-500 w-16 rounded-full p-2">
                <svg
                  className="h-12 w-12 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                >
                  <path d="M9.5 10.287c0-.41-.336-.743-.75-.743s-.75.333-.75.743.336.743.75.743.75-.333.75-.743zm4.5.495c0-.137-.112-.248-.25-.248h-3.5c-.138 0-.25.111-.25.248s.112.248.25.248h3.5c.138-.001.25-.112.25-.248zm2-.495c0-.41-.336-.743-.75-.743s-.75.333-.75.743.336.743.75.743.75-.333.75-.743zm-8.649-3.219h-1.101c-.138 0-.25.111-.25.248v.253c0 .393.463.49.808.49l.543-.991zm9.659 1.569c-.435-.8-.866-1.597-1.342-2.382-.393-.649-.685-.96-1.375-1.083-.698-.124-1.341-.172-2.293-.172s-1.595.048-2.292.172c-.69.123-.982.433-1.375 1.083-.477.785-.907 1.582-1.343 2.382-.344.63-.49 1.194-.49 1.884 0 .653.21 1.195.5 1.89v1.094c0 .273.224.495.5.495h.75c.276 0 .5-.222.5-.495v-.495h6.5v.495c0 .273.224.495.5.495h.75c.276 0 .5-.222.5-.495v-1.094c.29-.695.5-1.237.5-1.89 0-.69-.146-1.254-.49-1.884zm-7.821-1.873c.335-.554.426-.569.695-.617.635-.113 1.228-.157 2.116-.157s1.481.044 2.116.156c.269.048.36.064.695.617.204.337.405.687.597 1.03-.728.11-2.01.266-3.408.266-1.524 0-2.759-.166-3.402-.275.19-.34.389-.686.591-1.02zm5.798 5.256h-5.974c-.836 0-1.513-.671-1.513-1.498 0-.813.253-1.199.592-1.821.52.101 1.984.348 3.908.348 1.74 0 3.28-.225 3.917-.333.332.609.583.995.583 1.805 0 .828-.677 1.499-1.513 1.499zm2.763-4.952c.138 0 .25.111.25.248v.253c0 .393-.463.49-.808.49l-.543-.99h1.101zm-5.75-7.068c-5.523 0-10 4.394-10 9.815 0 5.505 4.375 9.268 10 14.185 5.625-4.917 10-8.68 10-14.185 0-5.421-4.478-9.815-10-9.815zm0 18c-4.419 0-8-3.582-8-8s3.581-8 8-8c4.419 0 8 3.582 8 8s-3.581 8-8 8z" />
                </svg>
              </div>
              <div className="mt-4 font-bold">Smart</div>
              <div className="text-center mt-2 text-gray-600 text-sm">
                With access to hundreds of journeys per month, you can quickly find
                people nearby travelling between Gauteng and Limpopo, Mpumalanga and Eastern Cape.
              </div>
            </div>
          </div>
          <div className="mt-4 sm:w-1/3">
            <div className="bg-gray-100 h-full p-8 border-b-4 border-green-500 rounded-lg flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
              <div className="bg-gray-200 text-green-500 w-16 rounded-full p-2">
                <svg
                  className="h-12 w-12 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.429 6l-5.144 8h3.21l-1.495 4 6-7h-3.896l1.325-5z" />
                </svg>
              </div>
              <div className="mt-4 font-bold">Simple</div>
              <div className="text-center mt-2 text-gray-600 text-sm">
                Enter your departure and destination to find the perfect ride, choose who
                you’d like to travel with and book!
              </div>
            </div>
          </div>
          <div className="mt-4 sm:w-1/3">
            <div className="bg-gray-100 h-full p-8 border-b-4 border-green-500 rounded-lg flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
              <div className="bg-gray-200 text-green-500 w-16 rounded-full p-2">
                <svg
                  className="h-12 w-12 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.286 9c-3.009 0-4.207 5-6.571 5-1.057 0-1.74-.789-1.74-2s.684-2 1.74-2c.833 0 1.431.535 2.009 1.197l.622-.781c-.75-.766-1.526-1.416-2.631-1.416-1.599 0-2.715 1.227-2.715 3s1.116 3 2.715 3c3.002 0 4.203-5 6.571-5 1.057 0 1.739.789 1.739 2s-.683 2-1.739 2c-.832 0-1.43-.534-2.007-1.196l-.622.78c.749.766 1.525 1.416 2.629 1.416 1.599 0 2.714-1.227 2.714-3s-1.115-3-2.714-3z" />
                </svg>
              </div>
              <div className="mt-4 font-bold">Seamless</div>
              <div className="text-center mt-2 text-gray-600 text-sm">
                Get to your exact destination(Yes, to your door!), or nearest town without the hassle. No queues.
                No waiting around.
              </div>
            </div>
          </div>
          <div className="mt-4 sm:w-1/3">
            <div className="bg-gray-100 h-full p-8 border-b-4 border-green-500 rounded-lg flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
              <div className="bg-gray-200 text-green-500 w-16 rounded-full p-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 fill-current" width={24} height={24} viewBox="0 0 640 512"><path d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"/></svg>
              </div>
              <a href="https://parcelin.co.za" target="blank"><div className="mt-4 font-bold">Parcelling too</div></a>
              <div className="text-center mt-2 text-gray-600 text-sm">
                Get your parcel to it's cross province destination same day, overnight or next friday, cheaper than any other alternative. Click <a className="brand-link" target="blank" href="https://parcelin.co.za">here</a> to send yours.
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-6xl mx-4 p-5">
          <div className="p-12 text-center max-w-12xl">
            <div className="md:text-3xl text-3xl font-bold">
              Where Are You <span className="text-green-500">Going</span>?
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:-mx-2">
            <div className="mt-4 sm:w-1/3">
              <div className="bg-gray-100 h-full p-8 border-b-4 border-green-500 rounded-lg flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                <a
            type="button"
            className="kirk-item kirk-item--clickable sc-jIAOiI kzVgzs kirk-item-choice sc-lgVVsH jahIdN"
            href="#search"
          >
            <span className="kirk-item-leftWrapper">
              <span className="kirk-item-leftText">
                <span className="sc-eFWqGp jDbNdn">
                  <span className="font-semibold mb-2">Giyani</span>
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="kirk-icon sc-ksZaOG jucjqW sc-csvncw ijWgBF"
                    width={24}
                    height={24}
                    aria-hidden="true"
                  >
                    <g
                      fill="none"
                      stroke="#708C91"
                      strokeWidth={2}
                      strokeMiterlimit={10}
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      transform="rotate(180 12 12)"
                    >
                      <path d="M9 19l-7-7 7-7" />
                      <path d="M22 12H2" />
                    </g>
                  </svg>
                  <span className="font-semibold mb-2">Midrand</span>
                </span>
              </span>
            </span>
          </a>
              </div>
            </div>
            <div className="mt-4 sm:w-1/3">
              <div className="bg-gray-100 h-full p-8 border-b-4 border-green-500 rounded-lg flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
              <a
            type="button"
            className="kirk-item kirk-item--clickable sc-jIAOiI kzVgzs kirk-item-choice sc-lgVVsH jahIdN"
            href="#search"
          >
            <span className="kirk-item-leftWrapper">
              <span className="kirk-item-leftText">
                <span className="sc-eFWqGp jDbNdn">
                  <span className="font-semibold mb-2">Mbombela</span>
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="kirk-icon sc-ksZaOG jucjqW sc-csvncw ijWgBF"
                    width={24}
                    height={24}
                    aria-hidden="true"
                  >
                    <g
                      fill="none"
                      stroke="#708C91"
                      strokeWidth={2}
                      strokeMiterlimit={10}
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      transform="rotate(180 12 12)"
                    >
                      <path d="M9 19l-7-7 7-7" />
                      <path d="M22 12H2" />
                    </g>
                  </svg>
                  <span className="font-semibold mb-2">Soweto</span>
                </span>
              </span>
            </span>
     
          </a>              </div>
            </div>
            <div className="mt-4 sm:w-1/3">
              <div className="bg-gray-100 h-full p-8 border-b-4 border-green-500 rounded-lg flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
              <a
            type="button"
            className="kirk-item kirk-item--clickable sc-jIAOiI kzVgzs kirk-item-choice sc-lgVVsH jahIdN"
            href="#search"
          >
            <span className="kirk-item-leftWrapper">
              <span className="kirk-item-leftText">
                <span className="sc-eFWqGp jDbNdn">
                  <span className="font-semibold mb-2">Pretoria</span>
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="kirk-icon font-semibold sc-ksZaOG jucjqW sc-csvncw ijWgBF"
                    width={24}
                    height={24}
                    aria-hidden="true"
                  >
                    <g
                      fill="none"
                      stroke="#708C91"
                      strokeWidth={2}
                      strokeMiterlimit={10}
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      transform="rotate(180 12 12)"
                    >
                      <path d="M9 19l-7-7 7-7" />
                      <path d="M22 12H2" />
                    </g>
                  </svg>
                  <span className="font-semibold mb-2">Gqeberha</span>
                </span>
              </span>
            </span>
            
          </a>              </div>
            </div>
          </div>
        </div>




        <header className="bg-gray-200 w-full px-4 py-3 flex  border-b-4"></header>
        <div className="py-18">
            <div className="p-12 text-center max-w-12xl">
              <div className="md:text-3xl text-3xl font-bold">
                Frequently Asked <span className="text-green-500">Questions</span>
              </div>
            </div>
            
            <div className="px-10 sm:px-16">
              <Faq data={data} styles={styles} />
            </div>
        
        </div>

        <div className="py-18">
            <div className="p-10 text-center max-w-12xl">
              <div className="md:text-3xl text-3xl font-bold">
                Our <span className="text-green-500">Reviews</span>
              </div>
            </div>
            
            <div className="bg-gray-100 px-10 sm:px-16">
              <br></br>
              <div className="grid gap-6 text-center md:grid-cols-4 lg:gap-12">
                <div className="mb-12 md:mb-0">
                    <div className="mb-4 flex justify-center">
                      <div className="bg-gray-500 profile">
                        <div className="name">IM</div>
                      </div>
                    </div>
                    <h5 className="mb-2 text-xl font-semibold">Innocentia Mokgalaka</h5>
                    <h6 className="mb-2 font-semibold text-blue-600">
                        Driver
                    </h6>
                    <p className="mb-2 text-gray-600">
                      Excellent service, the safest way for door to door long distance travel 👌🏾
                    </p>
                    <Review />
                </div>
                <div className="mb-12 md:mb-0">
                    <div className="mb-4 flex justify-center">
                      <div className="bg-green-500 profile">
                        <div className="name">PM</div>
                      </div>
                    </div>
                    <h5 className="mb-2 text-xl font-semibold">Permission Mavasa</h5>
                    <h6 className="mb-2 font-semibold text-blue-600">
                        Rider
                    </h6>
                    <p className="mb-2 text-gray-600">
                      I enjoyed traveling with liftsearch 🥰it is reliable and the safest way to travel. Drivers are friendly and understanding. Keep up the good work!
                    </p>
                    <Review />
                </div>
                <div className="mb-12 md:mb-0">
                    <div className="mb-4 flex justify-center">
                      <div className="profile">
                        <div className="name">NM</div>
                      </div>                    </div>
                    <h5 className="mb-2 text-xl font-semibold">Ntsakisi Manganyi</h5>
                    <h6 className="mb-2 font-semibold text-blue-600">
                        Rider
                    </h6>
                    <p className="mb-2 text-gray-600">
                      Very reliable, dependable and very safe. Totally worth it.
                    </p>
                    <Review />
                </div>
                <div className="mb-12 md:mb-0">
                    <div className="mb-4 flex justify-center">
                      <div className="bg-blue-500 profile">
                        <div className="name">MR</div>
                      </div>
                    </div>
                    <h5 className="mb-2 text-xl font-semibold">Mandla Rikhotso</h5>
                    <h6 className="mb-2 font-semibold text-blue-600">
                        Driver
                    </h6>
                    <p className="mb-2 text-gray-600">
                      Excellent app Keep up the good work guys,our very own & we are humbled of this exceptional artwork. This heading up there to be a national treasure
                    </p>
                    <Review />
                </div>
              </div>
              <br></br>
            </div>

              {/* <a href="https://play.google.com/store/apps/details?id=com.lifts.liftsearch"  download="liftsearch-reviews.jpg"><img src={reviews} alt="reviews"/></a> */}
            <div className="ml-5">
            </div>
          </div>
        </div>
    </main>
    );
  }
}

export default Home;

import React, { Component } from "react";
import SubscriptionService from "../services/subscription.service";
import AuthService from "../services/auth.service";

export default class SingleTrip extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
          referrer: this.props.match.params.referrer,
          isDriver: this.props.match.params.referrer == 'driver',
          isPassenger: this.props.match.params.referrer == 'passenger',
          loading: true,
          successful: false,
          plans: [], 
          priceQuantity: 1
          // priceQuantity: this.props.match.params.referrer == 'driver' ? 2 : 1 // We want to mutliply the price by 2 if is driver
        };
    }
    
    componentDidMount(){
        SubscriptionService.plans().then((res) => {
            this.setState({
                plans: res,
                loading: false
            });
        })
    }

    handleSubmit(plan) {
        let currentUser = AuthService.getCurrentUser();

        SubscriptionService.subscribe(currentUser?.user?.id, plan.id).then((res) => {
            window.location.replace(`https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=11799876&item_name=Liftsearch%20${plan?.name}+Subscription&item_description=Liftsearch%20Subscription+R${plan?.price * this.state.priceQuantity}+${plan.name}+:+${res.id}&amount=${plan?.price * this.state.priceQuantity}&return_url=https://liftsearch.co.za/success_payment&cancel_url=https://liftsearch.co.za/cancel_payment`)
        })
    }

    render(){
            return(
            <main className="max-w-6xl mx-auto pt-10 pb-36 px-8">
            <div className="max-w-md mx-auto mb-14 text-center">
              <h1 className="text-4xl font-semibold mb-6 lg:text-5xl"><span className="text-green-600">Flexible</span> Plans</h1>
              <p className="text-xl text-gray-500 font-medium">Choose a plan that works best for you.</p>
            </div>
            
            <div className="flex flex-col justify-between items-center lg:flex-row lg:items-start">
                
                <div className="w-full flex-1 mt-8 p-8 order-2 bg-white shadow-xl rounded-3xl sm:w-96 lg:w-full lg:order-1 lg:rounded-r-none">
                    <div className="mb-7 pb-7 flex items-center border-b border-gray-300">
                        <img src="https://res.cloudinary.com/williamsondesign/abstract-1.jpg"  alt="" class="rounded-3xl w-20 h-20" />
                    <div className="ml-5">
                        <span className="block text-3xl font-semibold text-dark">{this.state.plans[0]?.name}</span>
                        <span><span className="font-medium text-xl align-top">R&thinsp;</span><span className="text-3xl font-bold text-dark">{this.state.plans[0]?.price * this.state.priceQuantity}</span></span><span className="font-medium"></span>
                    </div>
                    </div>
                    <hr></hr>
                    <ul className="mb-10 font-medium text-xl text-gray-500">
                    {  this.state.isDriver && <li className="flex mb-6">
                        <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                        <span className="ml-3">Keep all the money made on all <span className="text-dark">Trips</span> for the whole year</span>
                    </li> }
                    {  this.state.isDriver && <li className="flex mb-6">
                        <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                        <span className="ml-3">Get instant calls <span className="text-dark">from booked riders,</span> no waiting!</span>
                    </li> }
                    {  this.state.isDriver && <li className="flex mb-6">
                        <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                        <span className="ml-3">Contact <span className="text-dark">unpaid riders,</span> no waiting!</span>
                    </li> }
                    {  this.state.isPassenger && <li className="flex mb-6">
                        <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                        <span className="ml-3">Instantly contact <span className="text-dark">Driver</span></span>
                    </li> }
                    {  this.state.isPassenger && <li className="flex mb-6">
                        <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                        <span className="ml-3">Make as many <span className="text-dark">bookings</span> as you want</span>
                    </li> }
                    {  this.state.isPassenger && <li className="flex mb-6">
                        <img src="https://res.cloudinary.com/williamsondesign/check-grey.svg" />
                        <span className="ml-3">Instant <span className="text-dark">Liftsearch</span> support</span>
                    </li> }
                    </ul>
                    <a href="#" onClick={() => {this.handleSubmit(this.state.plans[0])}} className="flex justify-center items-center bg-green-600 rounded-xl py-6 px-4 text-center text-white text-2xl">
                    Choose Plan
                    <img src="https://res.cloudinary.com/williamsondesign/arrow-right.svg" className="ml-2" />
                    </a>
              </div>  
              
              <div className="w-full flex-1 p-8 order-1 shadow-xl rounded-3xl bg-blue-900 text-gray-400 sm:w-96 lg:w-full lg:order-2 lg:mt-0">
                <div className="mb-8 pb-8 flex items-center border-b border-gray-600">
                    <img src="https://res.cloudinary.com/williamsondesign/abstract-1.jpg"  alt="" class="rounded-3xl w-20 h-20" />
                  <div className="ml-5">
                    <span className="block text-3xl font-semibold text-white">{this.state.plans[1]?.name}</span>
                    <span><span className="font-medium text-xl align-top">R&thinsp;</span><span className="text-3xl font-bold text-white">{this.state.plans[1]?.price * this.state.priceQuantity}</span></span><span className="font-medium"></span>
                  </div>
                </div>
                <hr></hr>
                <ul className="mb-10 font-medium text-xl">
                {  this.state.isDriver && <li className="flex mb-6">
                    <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" />
                    <span className="ml-3">Keep all the money made on <span className="text-white">Trips</span> for the whole month</span>
                  </li> }
                {  this.state.isDriver &&<li className="flex mb-6">
                    <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" />
                    <span className="ml-3">Contact <span className="text-white">unpaid riders,</span> no waiting</span>
                  </li> }
                {  this.state.isDriver &&<li className="flex mb-6">
                    <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" />
                    <span className="ml-3">Get instant calls <span className="text-white">from booked riders,</span> no waiting</span>
                  </li> }
                {  this.state.isPassenger &&  <li className="flex mb-6">
                    <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" />
                    <span className="ml-3">Instantly contact <span className="text-white">Driver</span></span>
                  </li> }
                {  this.state.isPassenger &&  <li className="flex mb-6">
                    <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" />
                    <span className="ml-3">Make as many <span className="text-white">bookings</span> as you want</span>
                  </li> }
                  {  this.state.isPassenger &&  <li className="flex mb-6">
                    <img src="https://res.cloudinary.com/williamsondesign/check-white.svg" />
                    <span className="ml-3">Instant <span className="text-white">Liftsearch</span> support</span>
                  </li> }
                </ul>
                <a href="#" onClick={() => {this.handleSubmit(this.state.plans[1])}}  className="flex justify-center items-center bg-green-600 rounded-xl py-6 px-4 text-center text-white text-2xl">
                  Choose Plan
                  <img src="https://res.cloudinary.com/williamsondesign/arrow-right.svg" className="ml-2" />
                </a>
              </div>
            </div>
            </main>)
    }
}


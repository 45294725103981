import axios from "axios";
import authHeader from './auth-header';
require('dotenv').config()

const API_URL = process.env.REACT_APP_API_URL;

class SubscriptionService {
  plans() {
    return axios.get(API_URL + "plans", {headers: authHeader()}).then(response => {
        return response.data;
    });
  }

  subscribe(user_id, plan_id) {
    return axios
      .post(API_URL + "subscriptions", {
        user_id, 
        plan_id
      }, {headers: authHeader()})
      .then(response => {
        return response.data;
      });
  }
}

export default new SubscriptionService();
import React, { Component } from "react";
import { BaseSection } from "@blablacar/ui-library/build/layout/section/baseSection";
import { TabsSection } from '@blablacar/ui-library/build/layout/section/tabsSection';
import './home.css';

class Terms extends Component {

  componentDidMount(){
  }

  render() {
    return (
      <div>
        <br/>
        <TabsSection tabsProps={{
          activeTabId: 'terms',
          tabs: [{
            id: 'terms',
            label: 'Terms & Conditions',
            panelContent: 
            <BaseSection>
              <br/>
              <br/>
              
              <main className="o-main">
                <h1 className="o-custom-page__title c-post__title">Terms &amp; Conditions</h1>
                <div className="o-custom-page">
                  <div className="o-custom-page__content">
                    <div className="s-post-content s-custom-page__cms">
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>1.&nbsp;&nbsp; &nbsp;Subject</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>Earlybirds Corporation (hereinafter referred to as “<strong>Liftsearch</strong>”) has developed a carpooling platform accessible on a website at the address <a href="http://www.liftsearch.co.za">www.liftsearch.co.za</a> or in the form of a mobile application, and designed to put drivers travelling to a given destination in contact with passengers going in the same direction, in order to enable them to share the Trip and therefore the associated costs (hereinafter referred to as the “<strong>Platform</strong>”).</p>
                        <p style={{textAlign:  "justify"}}>These terms and conditions have the purpose of governing access to and the terms of use of the Platform. Please read them carefully. You understand and recognise that Liftsearch is not party to any agreement, contract or contractual relations, of any nature, entered into between the Members of its Platform.</p>
                        <p style={{textAlign:  "justify"}}>By clicking “Login with Facebook” or “Register with an email address”, you recognise having read and accepted all these general conditions of use.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>2.&nbsp;&nbsp; &nbsp;Definitions</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>In this document,</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Advert</strong>” means an advertisement about a Trip posted on the Platform by a Driver;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Liftsearch</strong>” has the meaning given to it in Article 1 above;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>T&amp;Cs</strong>” means these Terms and Conditions;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Account</strong>” means the account that must be created in order to become a Member and access certain services offered by the Platform;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Facebook Account</strong>” has the meaning given to it in Article 3.2 below;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Driver</strong>” means the Member using the Platform, to offer to transport another individual in exchange for the Cost Contribution, for a Trip and at a time defined by the Driver alone;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Booking Confirmation</strong>” has the meaning given to it in Article 4.2.1 below;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Member Content</strong>” has the meaning given to it in Article 11.2 below</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Service Fee</strong>” has the meaning given to it in Article 5.2 below;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Member</strong>” means any individual having created an Account on the Platform;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Passenger</strong>” means the Member having accepted the offer to be transported by the Driver or, where applicable, the person on whose behalf a Member has booked a Seat;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Cost Contribution</strong>” means, for a given Trip, the sum of money requested by the Driver and accepted by the Passenger for his contribution to the travel costs;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Seat</strong>” means the seat booked by a Passenger in the Driver’s vehicle;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Platform</strong>” has the meaning given to it in Article 1 above;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Booking</strong>” has the meaning given to it in Article 4.2.1. below;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Services</strong>” means all services delivered by Liftsearch via the Platform;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Website</strong>” means the website accessible at the address www.liftsearch.co.za;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Leg</strong>” has the meaning given to it in Article 4.1 below;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Trip</strong>” means the journey subject of an Advert published by a Driver on the Platform, and for which he agrees to transport Passengers in exchange for the Cost Contribution;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Trip with Booking</strong>” has the meaning given to it in Article 4.2.1 below;</p>
                        <p style={{textAlign:  "justify"}}>“<strong>Trip without Booking</strong>” has the meaning given to it in Article 4.2.2 below;</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>3.&nbsp;&nbsp; &nbsp;Registration on the Platform and creation of an Account</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>3.1.&nbsp;&nbsp; &nbsp;Conditions of registration on the Platform</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>The Platform may be used by individuals aged 18 or over. Registration on the platform by a minor is strictly prohibited. In accessing, using or registering on the Platform, you represent and warrant that you are aged 18 or over.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>3.2.&nbsp;&nbsp; &nbsp;Creation of an Account</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>The Platform enables Members to post and view Adverts and to interact with each other to book a Seat. You can view the Adverts if you are not registered on the Platform. However, you cannot post an Advert or book a Seat without having first created an Account and become a Member.</p>
                        <p style={{textAlign:  "justify"}}>To create your Account, you can:</p>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(i)&nbsp;&nbsp; &nbsp;either complete all mandatory fields on the registration form;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ii)&nbsp;&nbsp; &nbsp;or log in to your Facebook account via our Platform (hereinafter referred to as your “Facebook Account”). In using such functionality, you understand that Liftsearch will have access to, will publish on the Platform, and will keep certain information from your Facebook Account. You can delete the link between your Account and your Facebook Account at any time through the “Verifications” section of your profile. If you wish to find out more about the use of your data from your Facebook Account, read our <a href="https://www.liftsearch.co.za/about-us/privacy-policy">Privacy Policy</a> and that of Facebook.</li>
                        </ul>
                        <p style={{textAlign:  "justify"}}>To register on the Platform, you must have read and accepted these T&amp;Cs and the <a href="https://www.liftsearch.co.za/about-us/privacy-policy">Privacy Policy</a>.</p>
                        <p style={{textAlign:  "justify"}}>When creating your Account, regardless of the method chosen, you agree to provide accurate and true information, and to update it through your profile or by notifying Liftsearch, in order to guarantee its relevance and accuracy throughout your contractual relations with Liftsearch.</p>
                        <p style={{textAlign:  "justify"}}>In the case of registration by email, you agree to keep secret the password chosen upon creating your Account and not to communicate it to anybody. If you lose or disclose your password, you undertake to inform Liftsearch immediately. You alone are responsible for the use of your Account by third parties, unless you have expressly notified Liftsearch of the loss, the fraudulent use by a third party, or the disclosure of your password to a third party.</p>
                        <p style={{textAlign:  "justify"}}>You agree not to create or use, under your own identity or that of a third party, Accounts other than that initially created.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>3.3.&nbsp;&nbsp; &nbsp;Verification</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>Liftsearch may, for the purposes of transparency, improving trust, or prevention or detection of fraud, set up a system for verification of some of the information you provide on your profile. This is notably the case when you enter your telephone number or provide us with an Identity document.</p>
                        <p style={{textAlign:  "justify"}}>You recognise and accept that any reference on the Platform or the Services to “verified” information, or any similar term, means only that a Member has successfully passed the verification procedure existing on the Platform or the Services in order to provide you with more information about the Member with whom you are considering travelling. Liftsearch cannot guarantee the truthfulness, reliability or validity of the information subject of the verification procedure.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>4.&nbsp;&nbsp; &nbsp;Use of the Services</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>4.1.&nbsp;&nbsp; &nbsp;Posting Adverts</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>As Member, and providing you fulfil the conditions below, you can create and post Adverts on the Platform by entering information about the Trip you intend to make (dates/times and collection points and arrival, number of seats offered, options available, amount of the Cost Contribution, etc.).</p>
                        <p style={{textAlign:  "justify"}}>When posting your Advert, you can indicate the milestone cities in which you agree to stop, to pick up or drop off Passengers. The sections of the Trip between these milestone cities or between one of these milestone towns and the collection point or destination of the Trip constitute “<strong>Legs</strong>”.</p>
                        <p style={{textAlign:  "justify"}}>You are only authorised to post an Advert if you fulfil all the following conditions:</p>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(i)&nbsp;&nbsp; &nbsp;you hold a valid driving licence;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ii)&nbsp;&nbsp; &nbsp;you only offer Adverts for vehicles you own or use with the express permission of the owner, and in all cases that you are authorised to use for the purposes of carpooling;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iii)&nbsp;&nbsp; &nbsp;you are and remain the main driver of the vehicle subject of the Advert;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iv)&nbsp;&nbsp; &nbsp;the vehicle has valid third party insurance;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(v)&nbsp;&nbsp; &nbsp;you have no contraindication or medical incapacity for driving;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(vi)&nbsp;&nbsp; &nbsp;the vehicle you intend to use for the Trip is a touring car with 4 wheels and a maximum of 7 seats;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(vii)&nbsp;&nbsp; &nbsp;you do not intend posting another advert for the same Trip on the Platform;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(viii)&nbsp;&nbsp; &nbsp;you do not offer more Seats than the number available in your vehicle;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ix)&nbsp;&nbsp; &nbsp;all Seats offered have a seatbelt, even if the vehicle is approved with seats having no seatbelt;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(x)&nbsp;&nbsp; &nbsp;to use a vehicle in good working order and which complies with the applicable legal provisions and customs, notably with an up-to-date MoT certificate.</li>
                        </ul>
                        <p style={{textAlign:  "justify"}}>You recognise that you are solely responsible for the content of the Advert you post on the Platform. Consequently, you represent and warrant the accuracy and truthfulness of all information contained in your Advert, and you undertake to fulfil the Trip under the conditions described in your Advert.</p>
                        <p style={{textAlign:  "justify"}}>Providing your Advert complies with the T&amp;Cs, it will be posted on the Platform and therefore visible to Members and all visitors, even non-members, conducting a search on the Platform or on the website of Liftsearch’s partners. Liftsearch reserves the right, at its sole discretion and without notice, to not post or to remove, at any time, any Advert that does not comply with the T&amp;Cs or that it considers as damaging to its image, that of the Platform, or that of the Services.</p>
                        <p style={{textAlign:  "justify"}}>You recognise and accept that the criteria taken into account in the classification and the order of display of your Advert among the other Adverts are at the sole discretion of Liftsearch.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>4.2.&nbsp;&nbsp; &nbsp;Booking a Seat</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>The methods of booking of a Seat depend on the nature of the Trip envisaged. For some Trips, Liftsearch has set up an online booking system.</p>
                        <p style={{textAlign:  "justify"}}><strong>4.2.1.&nbsp;&nbsp; &nbsp;</strong><span style={{textDecoration:  "underline"}}><strong>Trip with Booking</strong></span></p>
                        <p style={{textAlign:  "justify"}}>Liftsearch has set up a system for booking Seats online (“<strong>Booking</strong>”) for some Trips offered on the Platform (“<strong>Trips with Booking</strong>”).</p>
                        <p style={{textAlign:  "justify"}}>Eligibility of a Trip for the Booking system remains at the sole discretion of Liftsearch, which reserves the right to modify these conditions at any time.</p>
                        <p style={{textAlign:  "justify"}}>When a Passenger is interested in an Advert benefiting from Booking, he may make an online Booking request. This Booking request is either (i) accepted automatically (if the Driver chose this option when posting his Advert), or (ii) accepted manually by the Driver. At the time of the Booking, the Passenger makes online payment of the Cost Contribution and the related Service Fees, where applicable. After receipt of the payment by Liftsearch and validation of the Booking request by the Driver, where applicable, the Passenger receives a Booking Confirmation (the “<strong>Booking Confirmation</strong>”).</p>
                        <p style={{textAlign:  "justify"}}>If you are a Driver and have chosen to handle manually the Bookings requests when posting your Advert, you are required to respond to any Booking request within the timeframe specified by the Passenger during the Booking request. Otherwise, the Booking request will expire automatically and the Passenger shall be refunded all sums paid at the time of the Booking request, if any.</p>
                        <p style={{textAlign:  "justify"}}>At the time of the Booking Confirmation, Liftsearch will send you the telephone number of the Driver (if you are the Passenger) or of the Passenger (if you are the Driver). You are then solely responsible for executing the contract binding you to the other Member.</p>
                        <p style={{textAlign:  "justify"}}><strong>4.2.2.&nbsp;&nbsp; &nbsp;</strong><span style={{textDecoration:  "underline"}}><strong>Trip without Booking</strong></span></p>
                        <p style={{textAlign:  "justify"}}>For journeys not benefiting from the online system for booking Seats (“Trips without Booking”), Liftsearch offers a free service (i.e. without Service Fee) enabling the Passenger to send a message to the Driver of a Trip he may be interested in. At the time of sending this message, Liftsearch will send you the telephone number of the Driver (if you are the Passenger) or of the Passenger (if you are the Driver), if the Member agreed to display his/her phone number. You are then solely responsible for arranging the details of the Trip with the other Member, and you alone assume the risks linked to cancellations, changes of the details of the journey, and non-payment of the Cost Contribution.</p>
                        <p style={{textAlign:  "justify"}}><strong>4.2.3.&nbsp;</strong>&nbsp; <span style={{textDecoration:  "underline"}}><strong>&nbsp;Named nature of the Seat booking and terms of use of the Services on behalf of a third party</strong></span></p>
                        <p style={{textAlign:  "justify"}}>Any use of the Services, in the capacity of Passenger or Driver, relates to a specific name. The Driver and the Passenger must correspond to the identity communicated to Liftsearch and the other Members participating in the Trip.</p>
                        <p style={{textAlign:  "justify"}}>However, Liftsearch allows its Members to book one or more Seats on behalf of a third party. In this case, you undertake to accurately indicate to the Driver, at the time of the Booking or when sending the message to the Driver (in the context of a Trip without Booking), the forenames, age and telephone number of the person on whose behalf you are reserving a Seat. It is strictly prohibited to book a Seat for a minor under the age of 13 travelling alone. In the case where you are reserving a seat for a minor over the age of 13 travelling alone, you undertake to request the prior agreement of the Driver and to provide him with duly completed and signed permission of his/her legal representatives.</p>
                        <p style={{textAlign:  "justify"}}>In addition, the Platform is intended for the booking of Seats for individuals. It is forbidden to book a Seat for transporting any object, package, animal traveling alone or material items whatsoever.</p>
                        <p style={{textAlign:  "justify"}}>Furthermore, it is prohibited to publish an Advert for a Driver other than you.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>4.3.&nbsp;&nbsp; &nbsp;Review system</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}><strong>4.3.1.&nbsp;&nbsp; &nbsp;</strong><span style={{textDecoration:  "underline"}}><strong>Operation</strong></span></p>
                        <p style={{textAlign:  "justify"}}>Liftsearch encourages you to leave a review about a Driver (if you are a Passenger) or about a Passenger (if you are a Driver) with whom you have shared a Trip or with whom you were supposed to share a Trip. However, you are not permitted to leave a review about another Passenger if you were a Passenger, or about a Member with whom you have not travelled or with whom you were not supposes to travel.</p>
                        <p style={{textAlign:  "justify"}}>Your review, and the review left by another Member about you, if any,, are only visible and published on the Platform after the shorter of the following periods: (i) immediately after you have both left a review, or (ii) after a period of 14 days following the first review..</p>
                        <p style={{textAlign:  "justify"}}>You have the option of responding to a review that another Member has left on your profile in a 14-day delay following the date of the review received. The review and your response, where applicable, will be published on your profile.</p>
                        <p style={{textAlign:  "justify"}}><strong>4.3.2.</strong>&nbsp;&nbsp; &nbsp;<span style={{textDecoration:  "underline"}}><strong>Moderation</strong></span></p>
                        <p style={{textAlign:  "justify"}}>You recognise and accept that Liftsearch reserves the right not to publish or to delete any review, any question, any comment or any response if it deems its content breaches these T&amp;Cs.</p>
                        <p style={{textAlign:  "justify"}}><strong>4.3.3.</strong>&nbsp;&nbsp; &nbsp;<span style={{textDecoration:  "underline"}}><strong>Limit</strong></span></p>
                        <p style={{textAlign:  "justify"}}>Liftsearch reserves the right to suspend your Account, limit your access to the Services, or terminate these T&amp;Cs in the case where (i) you have received at least three reviews and (ii) the average review you have received is less than or equal to 3.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>5.&nbsp;&nbsp; &nbsp;Financial conditions</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>Access to and registration on the Platform, as well as searching, viewing and posting Adverts, are free of charge. However, the Booking is charged under the conditions described below.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>5.1.&nbsp;&nbsp; &nbsp;Cost Contribution</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>The Cost Contribution is determined by you, as Driver, under your sole responsibility. It is strictly prohibited to profit in any way from using our Platform. Consequently, you agree to limit the Cost Contribution you ask your Passengers to pay to the costs you actually incur to make the Trip. Otherwise, you alone will bear the risks of recharacterization of the transaction completed via the Platform.</p>
                        <p style={{textAlign:  "justify"}}>When you post an Advert, Liftsearch will suggest an amount of for the Cost Contribution, which notably takes account of the nature of the Trip and the distance travelled. This amount is given purely as a guideline and it is up to you to increase it or reduce it to take account of the costs you actually incur on the Trip. To avoid abuse, Liftsearch limits the possibility of adjustment of the Cost Contribution.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>5.2.&nbsp;&nbsp; &nbsp;Service Fees</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>In the context of Trips with Booking, Liftsearch, in exchange for use of the Platform, at the time of Booking, shall collect service fees (hereinafter referred to as the “Service Fees”) calculated on the basis of the Cost Contribution. The methods of calculation of the Service Fees in force can be found <a href="https://www.liftsearch.co.za/blablalife/lp/service-fees">here</a>.</p>
                        <p style={{textAlign:  "justify"}}>The Service Fees are collected by Liftsearch for each Seat subject of a Booking by a Passenger.</p>
                        <p style={{textAlign:  "justify"}}>In the case of cross-border journeys, please note that the methods of calculation of the amount of the Service Fees and the applicable VAT vary according to the residence of the Passenger.</p>
                        <p style={{textAlign:  "justify"}}>When using the Platform for cross-border trips or out of the United Kingdom, the Service Fees may be charged by an affiliate company of Liftsearch operating the local platform.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>5.3.&nbsp;&nbsp; &nbsp;Rounding</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>You recognise and accept that Liftsearch may, at its full discretion, round up or down the Service Fees and the Cost Contribution.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>5.4.&nbsp;&nbsp; &nbsp;Methods of payment and repayment of the Cost Contribution to the Driver</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}><strong>5.4.1.&nbsp;&nbsp; &nbsp;<span style={{textDecoration:  "underline"}}>Collection mandate</span></strong></p>
                        <p style={{textAlign:  "justify"}}>In using the Platform as Driver for Trips with Booking, you confer on Liftsearch a collection mandate for the Cost Contribution in your name and on your behalf.</p>
                        <p style={{textAlign:  "justify"}}>Consequently, in the context of a Trip with Booking, and after manual or automatic acceptance of the Booking, Liftsearch shall collect the whole of the sum paid by the Passenger (Service Fees and Cost Contribution).</p>
                        <p style={{textAlign:  "justify"}}>The Cost Contributions received by Liftsearch are deposited in an account dedicated to payment of the Drivers.</p>
                        <p style={{textAlign:  "justify"}}>You recognise and accept that none of the sums received by Liftsearch in the name and on behalf of the Driver gives entitlement to interest. You agree to respond diligently to any request of Liftsearch, and more generally of any administrative or court authority competent particularly in the prevention or combating of money laundering. Notably, you agree to provide, on simple request, any useful evidence of address and/or identity.</p>
                        <p style={{textAlign:  "justify"}}>In the absence of response to these requests, Liftsearch may take any measure it deems appropriate, notably freezing of the sums paid and/or suspension of your Account and/or termination of these T&amp;Cs.</p>
                        <p style={{textAlign:  "justify"}}><strong>5.4.2.</strong>&nbsp;&nbsp; &nbsp;<span style={{textDecoration:  "underline"}}><strong>Payment of the Cost Contribution to the Driver</strong></span></p>
                        <p style={{textAlign:  "justify"}}>After the Trip, the Passengers shall have a period of 24 hours after the end of the Trip to submit a claim on the Trip to Liftsearch. In the absence of claim of the Passengers within this period, Liftsearch shall consider the Trip confirmed.</p>
                        <p style={{textAlign:  "justify"}}>From the time of this express or tacit confirmation, you shall have, as Driver, a credit payable on your Account. This credit corresponds to the total amount paid by the Passenger at the time of confirmation of the Booking reduced by the Service Fees, i.e. the Cost Contribution paid by the Passenger.</p>
                        <p style={{textAlign:  "justify"}}>When the Trip is confirmed by the Passenger, you have the option, as a Driver, of giving instructions to Liftsearch to pay the money to your bank account (by entering your bank details in advance in your Account) or your PayPal account (by entering your PayPal email address in advance in your Account).</p>
                        <p style={{textAlign:  "justify"}}>The payment order in your name will be sent on the first working day after your request or, if you do not so request, the first working day after&nbsp;the provision of the sums in question to your profile (providing Liftsearch has your bank information).</p>
                        <p style={{textAlign:  "justify"}}>At the end of the applicable time limitation periods, any sum not claimed from Liftsearch shall be deemed to belong to Liftsearch.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>6.&nbsp;&nbsp; &nbsp;Non-commercial and non-business purpose of the Services and the Platform</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>You agree to use the Services and the Platform only to be put into contact, on a non-business and non-commercial basis, with people wishing to share a Trip with you.</p>
                        <p style={{textAlign:  "justify"}}>As Driver, you agree not to request a Cost Contribution higher than the costs you actually incur and that may generate a profit, being specified that in the context of a cost sharing, you have to bear as a Driver your own part of the costs induced by the Trip. You are solely responsible for calculating the costs you incur for the Trip and for checking that the amount requested of your Passengers does not exceed the costs you actually incur (excluding your share of the costs).</p>
                        <p style={{textAlign:  "justify"}}>Liftsearch reserves the right to suspend your Account in the case where you use a chauffeur-driven or other business vehicle or taxi, or a company car, and due to this, generate a profit from the Platform. You agree to provide to Liftsearch, on its simple request, a copy of your car registration certificate and/or any other document showing that you are authorised to use this vehicle on the Platform and are not drawing any profit therefrom.</p>
                        <p style={{textAlign:  "justify"}}>Liftsearch also reserves the right to suspend your Account, limit your access to the Services, or terminate these T&amp;Cs, in the case of activity by you on the Platform which, owing to the nature of the Trips offered, their frequency, the number of Passengers carried and the Cost Contribution requested, entails a situation of profit for you, or for any reason suggesting to Liftsearch that you are generating a profit on the Platform.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>7.&nbsp;&nbsp; &nbsp;Cancellation policy</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>7.1.&nbsp;&nbsp; &nbsp;Terms of repayment in the case of cancellation</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>Only Trips with Booking are the subject of this cancellation policy; Liftsearch does not offer any guarantee, of any nature, in the case of cancellation for any reason, by a Passenger or a Driver, of a Trip without Booking.</p>
                        <p style={{textAlign:  "justify"}}>The cancellation of a Seat on a Trip with Booking by the Driver or the Passenger after Booking Confirmation is subject to the stipulations hereunder:</p>
                        <p style={{textAlign:  "justify"}}>–&nbsp;&nbsp; &nbsp;In the case of cancellation owing to the Driver, the Passenger is refunded the whole of the sum paid (i.e. the Cost Contribution and the related Service Fees). This is notably the case when Driver cancels a Trip or has not arrived at the meeting point 15 minutes after the agreed time;</p>
                        <p>–&nbsp;&nbsp; &nbsp;In the case of cancellation owing to the Passenger:</p>
                        <ul>
                          <li>&nbsp;If the Passenger cancels more than 24 hours before the planned departure time as mentioned in the Advert, the Passenger shall only be refunded the Cost Contribution. The Service Fees are retained by Liftsearch and the Driver does not receive any sum of any nature;</li>
                          <li>If the Passenger cancels 24 hours or less before the planned departure time as mentioned in the Advert, and more than thirty minutes after the Booking Confirmation, the Passenger shall be refunded half the Cost Contribution paid at the time of Booking, the Service Fees are retained by Liftsearch, and the Driver receives 50% of the Cost Contribution;</li>
                          <li>If the Passenger cancels 24 hours or less before the planned departure time, as mentioned in the Advert, and thirty minutes or less after the Booking Confirmation, the Passenger is refunded the whole of the Cost Contribution. The Service Fees are retained by Liftsearch and the Driver does not receive any sum of any nature;</li>
                          <li>If the Passenger cancels after the planned departure time as mentioned in the Advert, or if he has not arrived at the meeting place 15 minutes after the agreed time, no refund shall be issued. The Driver shall be compensated with the whole of the Cost Contribution and the Service Fees are kept by Liftsearch.</li>
                        </ul>
                        <p>When cancellation occurs before departure and owing to the Passenger, the Seat(s) cancelled by the Passenger shall be automatically made available to other Passengers, who may book them online and they are accordingly subject to the conditions of these T&amp;Cs.<br />
                          Liftsearch appreciates, in its sole discretion, on the basis of the available information, the legitimacy of the reimbursement requests.
                        </p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>7.2.&nbsp;&nbsp; &nbsp;Right of withdrawal</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>You have no right of withdrawal from the time of Booking Confirmation provided the Contract between you and Liftsearch consisting of putting you in contact with another Member has been fully executed.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>8.&nbsp;&nbsp; &nbsp;Behaviour of users of the Platform and Members</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>8.1.&nbsp;&nbsp; &nbsp;Undertaking of all users of the Platform</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>You recognise being solely responsible for respecting all laws, regulations and obligations applicable to your use of the Platform.</p>
                        <p style={{textAlign:  "justify"}}>Furthermore, when using the Platform and during Trips, you undertake:</p>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(i)&nbsp;&nbsp; &nbsp;not to use the Platform for professional, commercial or profit-making purposes;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ii)&nbsp;&nbsp; &nbsp;not to send Liftsearch (notably upon creation or updating of your Account) or the other Members any false, misleading, malicious or fraudulent information;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iii)&nbsp;&nbsp; &nbsp;not to speak or behave in any way or post any content on the Platform of a defamatory, injurious, obscene, pornographic, vulgar, offensive, aggressive, uncalled-for, violent, threatening, harassing, racist or xenophobic nature, or with sexual connotations, inciting violence, discrimination or hatred, encouraging activities or the use of illegal substances, or more generally contrary to the purposes of the Platform, that may infringe the rights of Liftsearch or a third party or contrary to good morals;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iv)&nbsp;&nbsp; &nbsp;not to infringe the rights and image of Liftsearch, notably its intellectual property rights;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(v)&nbsp;&nbsp; &nbsp;not to open more than one Account on the Platform and not to open an Account in the name of a third party;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(vi) &nbsp; not to try to bypass the online booking system of the Platform, notably by trying to send another Member your contact details in order to make the booking outside of the Platform and avoid paying the Service Fees;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(vii)&nbsp;&nbsp; &nbsp;not to contact another Member, notably via the Platform, for a purpose other than defining the terms of the carpooling;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(viii)&nbsp;&nbsp; &nbsp;not to accept or make payment outside of the Platform, save in the cases authorised by these T&amp;Cs in the case of Trips without Booking;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ix)&nbsp;&nbsp; &nbsp;to comply with these T&amp;Cs and the <a href="https://www.liftsearch.co.za/about-us/privacy-policy">Privacy Policy</a>.</li>
                        </ul>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>8.2.&nbsp;&nbsp; &nbsp;Undertakings of the Drivers</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>Furthermore, when you use the Platform as Driver, you undertake:</p>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(i)&nbsp;&nbsp; &nbsp;to respect all laws, regulations and codes applicable to driving and the vehicle, notably to hold civil liability insurance valid at the time of the Trip and to be in possession of a valid driving licence;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ii)&nbsp;&nbsp; &nbsp;to check that your insurance covers carpooling and that your Passengers are considered as third parties in your vehicle and are therefore covered by your insurance during the whole Trip, even if cross-border;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iii)&nbsp;&nbsp; &nbsp;not to take any risk when driving, not to take any product that may impair your attention and your abilities to drive vigilantly and completely safely;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iv)&nbsp;&nbsp; &nbsp;to post Adverts corresponding only to Trips actually planned;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(v)&nbsp;&nbsp; &nbsp;to make the Trip as described in the Advert (notably with regard to using or not using the motorway) and to respect the times and places agreed with the other Members (notably meeting place and drop-off point);</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(vi)&nbsp;&nbsp; &nbsp;not to take more Passengers than the number of Seats indicated in the Advert;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(vii)&nbsp;&nbsp; &nbsp;to use a vehicle in good working order and which complies with the applicable legal provisions and customs, notably with an up-to-date MoT certificate;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(viii)&nbsp;&nbsp; &nbsp;to communicate to Liftsearch or any Passenger who so requests your driving licence, your car registration certificate, your insurance certificate, your MoT certificate and any document demonstrating your capacity to use the vehicle as Driver on the Platform;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ix)&nbsp;&nbsp; &nbsp;in the case of holdup or change to the time or the Trip, to inform your Passengers without delay;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(x)&nbsp;&nbsp; &nbsp;in the case of a cross-border Trip, to hold and keep available to the Passenger and any authority that may so request any document evidencing your identity and your right to cross the border;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(xi)&nbsp;&nbsp; &nbsp;to wait for Passengers at the agreed meeting place for at least 15 minutes after the agreed time;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(xii)&nbsp;&nbsp; &nbsp;not to post an Advert relative to a vehicle you do not own or that you are not authorised to use for the purposes of carpooling;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(xiii)&nbsp;&nbsp; &nbsp;to ensure you can be contacted by your Passengers by phone on the number registered on your profile;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(xiv)&nbsp;&nbsp; &nbsp;not to generate any profit via the Platform;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(xv)&nbsp;&nbsp; &nbsp;not to have any contraindication or medical incapacity for driving;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(xvi)&nbsp;&nbsp; &nbsp;to behave appropriately and responsibly during the Trip, and in compliance with the spirit of carpooling;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(xvii)&nbsp;&nbsp; &nbsp;not to decline any Booking based on race, color, ethnicity, national origin, religion, sexual orientation, marital status, disability, physical appearance, marital status, pregnancy, special vulnerability due to their economic situation, name, place of residence, health, political opinion, age.</li>
                        </ul>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>8.3.&nbsp;&nbsp; &nbsp;Undertakings of the Passengers</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>When you use the Platform as Passenger, you undertake:</p>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(i)&nbsp;&nbsp; &nbsp;to adopt appropriate behaviour during the Trip so as not to hinder the concentration or driving of the Driver or the peace and quiet of the other Passengers;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ii)&nbsp;&nbsp; &nbsp;to respect the Driver’s vehicle and its cleanliness;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iii)&nbsp;&nbsp; &nbsp;in the case of holdup, to inform the Driver without delay;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iv)&nbsp;&nbsp; &nbsp;in the case of Trips without Booking, to pay the Driver the agreed Cost Contribution;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(v)&nbsp;&nbsp; &nbsp;to wait for the Driver at the meeting place for at least 15 minutes beyond the agreed time;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(vi)&nbsp;&nbsp; &nbsp;to communicate to Liftsearch, or any Driver who so asks, your identity card or any document evidencing your identity;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(vii)&nbsp;&nbsp; &nbsp;not to carry during a Trip any item, goods, substance or animal that could hinder the driving and concentration of the Driver, or the nature, possession or carriage of which is contrary to the legal provisions in force;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(viii)&nbsp;&nbsp; &nbsp;in the case of a cross-border Trip, to hold and keep available to the Driver and any authority that may so request any document evidencing your identity and your right to cross the border;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ix)&nbsp;&nbsp; &nbsp;to ensure you can be contacted by the Driver by phone on the number registered on your profile, including at the meeting point.</li>
                        </ul>
                        <p style={{textAlign:  "justify"}}>In the event where you have made a Booking for one or more Seats on behalf of third parties, in compliance with the stipulations of Article 4.2.3 above, you guarantee respect by this third party for the stipulations of this article, and generally these T&amp;Cs. Liftsearch reserves the right to suspend your Account, limit your access to the Services, or terminate these T&amp;Cs, in the case of breach by the third party on whose behalf you have booked a Seat under these T&amp;Cs.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>9.&nbsp;&nbsp; &nbsp;Suspension of accounts, limitation of access and termination</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>You can terminate your contractual relations with Liftsearch at any time, without cost and without reason. To do this, simply go to the “Close my account” tab of your Profile page.</p>
                        <p style={{textAlign:  "justify"}}>In the event of (i) breach by you of these T&amp;Cs, including but limited to your obligations as Member mentioned in Articles 6 and 8 above, (ii) exceeding the limit set out in Article 4.3.3 above, or (iii) if Liftsearch has genuine reason to believe that this is necessary to protect its security and its integrity, that of the Members or third parties, or for the purpose of prevention of fraud or investigations, Liftsearch reserves the right to:</p>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(i)&nbsp;&nbsp; &nbsp;terminate the T&amp;Cs binding you with Liftsearch immediately and without notice; and/or</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ii)&nbsp;&nbsp; &nbsp;prevent the posting of or remove any review, Advert, message, content, Booking request, or any content posted by you on the Platform; and/or</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iii)&nbsp;&nbsp; &nbsp;limit your access and your use of the Platform; and/or</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iv)&nbsp;&nbsp; &nbsp;temporarily or permanently suspend your Account.</li>
                        </ul>
                        <p style={{textAlign:  "justify"}}>When this is necessary, you will be notified of the establishment of such measure in order to enable you to give explanations to Liftsearch. Liftsearch will decide, at its sole discretion, whether or not to lift the measures put in place.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>10.&nbsp;&nbsp; &nbsp;Personal data</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>In the context of your use of the Platform, Liftsearch will collect and process some of your personal data. In using the Platform and registering as Member, you recognise and accept the processing of your personal data by Liftsearch in compliance with the applicable law and the stipulations of the Privacy Policy.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>11.&nbsp;&nbsp; &nbsp;Intellectual property</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>11.1.&nbsp;&nbsp; &nbsp;Content published by Liftsearch</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>Subject to the contents provided by its Members, Liftsearch is the sole holder of all intellectual property rights relating to the Service, the Platform, its content (notably texts, images, designs, logos, videos, sounds, data, graphics) and to the software and databases ensuring their operation.</p>
                        <p style={{textAlign:  "justify"}}>Liftsearch grants you a non-exclusive, personal and non-transferable right to use the Platform and the Services, for your personal and private use, on a non-commercial basis and in compliance with the purposes of the Platform and the Services.</p>
                        <p style={{textAlign:  "justify"}}>You are prohibited from any other use or exploitation of the Platform and Services, and their content, without the prior written permission of Liftsearch. Notably, you are prohibited from:</p>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(i)&nbsp;&nbsp; &nbsp;reproducing, modifying, adapting, distributing, publicly representing and disseminating the Platform, the Services and the content, with the exception of that expressly authorised by Liftsearch;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ii)&nbsp;&nbsp; &nbsp;decompiling and reverse engineering the Platform or Services, subject to the exceptions stipulated by the texts in force;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iii)&nbsp;&nbsp; &nbsp;extracting or attempting to extract (notably using data mining robots or any other similar data collection tool) a substantial part of the data of the Platform.</li>
                        </ul>
                        <p style={{textAlign:  "justify"}}></p>
                        <h3>11.2.&nbsp;&nbsp; &nbsp;Content posted by you on the Platform</h3>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>In order to enable the provision of the Services, and in compliance with the purpose of the Platform, you grant Liftsearch a non-exclusive licence to use the content and data you provide in the context of your use of the Services (hereinafter referred to as your “<strong>Member Content</strong>”). In order to enable Liftsearch to distribute via the digital network and in accordance with any communication protocol (notably internet and mobile network), and to provide the content of the Platform to the public, you authorise Liftsearch, for the whole world and throughout the duration of your contractual relations with Liftsearch, to reproduce, represent, adapt and translate your Member Content as follows:</p>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(i)&nbsp;&nbsp; &nbsp;you authorise Liftsearch to reproduce all or part of your Member Content on any digital recording media, known or as yet unknown, and notably on any server, hard disk, memory card, or any other equivalent media, in any format and by any process, known or as yet unknown, to the extent necessary to any operation of storage, backup, transmission or download linked to the operation of the Platform and the provision of the Service;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ii)&nbsp;&nbsp; &nbsp;you authorise Liftsearch to adapt and translate your Member Content, and to reproduce these adaptations on any digital media, current or future, stipulated in point (i) above, with the aim of providing the Services, notably in different languages. This right notably includes the option to make modifications to the formatting of your Member Content, with respect for your moral right, for the purposes of respecting the graphics charter of the Platform and/or making it technically compatible with a view to its publication via the Platform.</li>
                        </ul>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>12.&nbsp;&nbsp; &nbsp;Role of Liftsearch</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>The Platform constitutes an online networking platform on which the Members can create and post Adverts for Trips for the purposes of carpooling. These Adverts can notably be viewed by the other Members to find out the terms of the Trip, and where applicable, to directly book a Seat in the vehicle in question with the Member having posted the Advert on the Platform.</p>
                        <p style={{textAlign:  "justify"}}>In using the Platform and in accepting these T&amp;Cs, you recognise that Liftsearch is not party to any agreement entered into between you and the other Members with a view to sharing the costs relating to a Trip.</p>
                        <p style={{textAlign:  "justify"}}>Liftsearch has no control over the behaviour of its Members and the users of the Platform. It does not own, exploit, supply or manage the vehicles subject of the Adverts, and it does not offer any Trips on the Platform.</p>
                        <p style={{textAlign:  "justify"}}>You recognise and accept that Liftsearch does not control the validity, truthfulness or legality of the Adverts, Seats and Trips offered. In its capacity of carpooling intermediary, Liftsearch does not provide any transport service and does not act in the capacity of carrier; the role of Liftsearch is limited to facilitating access to the Platform.</p>
                        <p style={{textAlign:  "justify"}}>The Members (Drivers or Passengers) act under their sole and full responsibility.</p>
                        <p style={{textAlign:  "justify"}}>In its capacity of intermediary, Liftsearch cannot be held liable for the effective occurrence of a Trip, and notably owing to:</p>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(i)&nbsp;&nbsp; &nbsp;erroneous information communicated by the Driver in his Advert, or by any other means, with regard to the Trip and its terms;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(ii)&nbsp;&nbsp; &nbsp;cancellation or modification of a Trip by a Member;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iii)&nbsp;&nbsp; &nbsp;non-payment of the Cost Contribution by a Passenger in the context of a Trip without Booking;</li>
                        </ul>
                        <ul>
                          <li style={{textAlign:  "justify"}}>(iv)&nbsp;&nbsp; &nbsp;the behaviour of its Members during, before or after the Trip.</li>
                        </ul>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>13.&nbsp;&nbsp; &nbsp;Operation, availability and functionalities of the Platform</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>Liftsearch shall try as far as possible to maintain the Platform accessible 7 days a week and 24 hours a day. Nevertheless, access to the Platform may be temporarily suspended, without notice, owing to technical maintenance, migration or update operations, or owing to outages or constraints linked to the operation of the network.</p>
                        <p style={{textAlign:  "justify"}}>Furthermore, Liftsearch reserves the right to modify or suspend all or part of access to the Platform or its functionalities, at its sole discretion, temporarily or permanently.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>14.&nbsp;&nbsp; &nbsp;Modification of the T&amp;Cs</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>These T&amp;Cs and the documents integrated by reference express the entire agreement between you and Liftsearch relative to your use of the Services. Any other document, notably any mention on the Platform (FAQ, etc.), is for guideline purposes only.</p>
                        <p style={{textAlign:  "justify"}}>Liftsearch may modify these T&amp;Cs in order to adapt to its technological and commercial environment, and to comply with the legislation in force. Any modification to these T&amp;Cs will be published on the Platform with mention of the date of effect, and you will be notified by Liftsearch before it takes effect.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>15.&nbsp;&nbsp; &nbsp;Applicable law – Dispute</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>These T&amp;Cs are written in English and subject to English law.</p>
                        <p style={{textAlign:  "justify"}}>You can also, if necessary, present your complaints relative to our Platform or our Services on the dispute resolution platform placed online by the European Commission, accessible <a href="https://webgate.ec.europa.eu/odr/main/?event=main.home.show&amp;lng=EN">here</a>. The European Commission will send your complaint to the competent national ombudsman. In compliance with the rules applicable to mediation, you are bound, before any request for mediation, to have notified Liftsearch in writing of any dispute in order to obtain an amicable solution.</p>
                        <p style={{textAlign:  "justify"}}></p>
                        <h2>16.&nbsp;&nbsp; &nbsp;Legal notices</h2>
                        <p></p>
                        <p style={{textAlign:  "justify"}}>The Platform is published by Earlybirds Corporation, limited company with a share of 100 shared, registered with the CIPC under number 2020/508296/07 (VAT number: Pending), represented by its Director, Vutivi Shivambu, Website Publication Director.</p>
                        <p style={{textAlign:  "justify"}}>The Website is hosted on the Earlybirds Corporation servers.</p>
                        <p style={{textAlign:  "justify"}}>For any question, you can contact Earlybirds Corporation using this <a href="https://www.liftsearch.co.za/contact">contact form</a>.</p>
                        <p style={{textAlign:  "justify"}}><em><strong>Version dated 12 March&nbsp;2021</strong></em></p>
                        <p style={{textAlign:  "justify"}}></p>
                    </div>
                  </div>
              </div>
            </main>
                
            </BaseSection>,
            badgeContent: ''
          }, {
            id: 'privacy',
            label: 'Privacy',
            panelContent: 
            <BaseSection noHorizontalSpacing>
              <br/>            
              <br/> 
              <main className="o-main">
                <h1 className="o-custom-page__title c-post__title">Privacy Policy</h1>
              </main>
            </BaseSection>,
            badgeContent: ''
          }, {
            id: 'cookie',
            label: 'Cookie',
            panelContent: 
            <BaseSection noHorizontalSpacing>
              <br/>            
              <br/> 
              <main className="o-main">
                <h1 className="o-custom-page__title c-post__title">Cookie Policy</h1>
              </main>
            </BaseSection>,
            badgeContent: ''
          }]
        }} />
      </div>
    );
  }
}

export default Terms;
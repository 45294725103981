import React, { Component } from "react";
import OrderService from "../services/order.service";

export default class SuccessPayment extends Component {
  constructor(props) {
    super(props);
    const orderIdFromProps = props.location.search?.split('=')?.filter(o => Object.values(o).some(v => v !== null));

    this.state = {
      order_id: orderIdFromProps.length > 0 ? orderIdFromProps[1] : -1
    };
  }

  componentDidMount(){
    OrderService.updatePaymentStatus(this.state.order_id).then(response => {
      console.log(JSON.stringify(response))
    });
  }

  render() {
    return (
      <div className="col-md-12 text-center">
        <br /> <br /> <br /> <br />
        <h5 className="mt-3 text-3xl font-extrabold text-gray-600">
            You have succesfully confirmed your order. Go <a className="text-green-600 underline text-small" href="http://onelink.to/bwg76p">back</a> to the app, click menu, my lifts and contact driver. Remember to only pay the remaining balance only to the driver.
        </h5>
      </div>
    );
  }
}
import React, { Component } from "react";
import TripService from "../services/trip.service";
import { Link } from "react-router-dom";
import { LayoutNormalizer } from "@blablacar/ui-library/build/layout/layoutNormalizer";
import { BaseSection } from "@blablacar/ui-library/build/layout/section/baseSection";
import { TheVoice } from "@blablacar/ui-library/build/theVoice";
import { Itinerary } from "@blablacar/ui-library/build/itinerary";
import { ItemData } from "@blablacar/ui-library/build/itemData";
import { ContentDivider } from "@blablacar/ui-library/build/divider/contentDivider";
import { ItemChoice } from "@blablacar/ui-library/build/itemChoice";
import { Loader } from '@blablacar/ui-library/build/loader'
import { Avatar } from '@blablacar/ui-library/build/avatar';
import AuthService from "../services/auth.service";
import carpool from '../carpool.png';
import phoneIcon from '../accept-call-icon.webp';

export default class SingleTrip extends Component {
  constructor(props) {
    super(props);
    this.onPriceClick = this.onPriceClick.bind(this);

    this.state = {
        currentUser: AuthService.getCurrentUser()?.user,
        trip_id: this.props?.match?.params?.trip_id,
        seats: this?.props?.location?.search.split('=')[1],
        trip: {},
        departure: {},
        destination: {},
        vehicle: {},
        driver: {},
        prices: {},
        order: null,
        price: "",
        isOwner: false,
        hasJoined: false,
        priceList: null,
        orderList: null,
        isAdmin: false,
        loading: true
    }
  }

  componentDidMount(){
    TripService.find(this.state.trip_id).then(response => {
      TripService.priceList().then(response => {
        this.setState({loading: false});
        this.setState({priceList: response.filter(elem => {
          return(this.state.prices.includes(elem.id))
        }).map((item, i) => {
            return(
              <label className="pl-4 items-center mt-3" key={i}>
                    <input onClick={this.onPriceClick} id={item.id} name="prices" value={item.id} type="radio" className="form-radio h-5 w-5 text-gray-600" />
                    <span className="ml-2 text-gray-700">{item.price_type.name } (R{item.value})</span>
              </label>
      )})})});

      this.setState({
            trip: response.data,
            hasJoined: response.data.orders.map(item => item.user_id).includes(this.state?.currentUser?.id),
            order: response.data.orders.filter(item => this.state?.currentUser?.id == item.user_id)[0],
            departure: response.data.departure,
            destination: response.data.destination,
            vehicle: response.data.vehicle,
            driver: response.data.user,
            prices: response.data.prices.map(item => item.id),
            isOwner: response.data.user_id === this.state?.currentUser?.id,
            isAdmin: this.state?.currentUser?.role === 'admin',
            orderList: response.data.orders});

    });
  }

  onPriceClick(e){
    this.setState({price: e.target.id});
  }

  render() {    
    return (
      <div className="container">
         {this.state.loading ? <Loader /> :
         <div>

          <LayoutNormalizer useLegacyNormalization={false} />
          <BaseSection noHorizontalSpacing>
              <div className="w-full">
                <div className="shadow-md">
                {this.state.isOwner || this.state.isAdmin ?
                    <div className="tab-show w-full overflow-hidden border-t">
                      <input className="absolute opacity-0" id="tab-show-single-one" type="radio" name="tab-shows2" />
                      <label className="block p-5 leading-normal cursor-pointer" style={{fontSize: "30px", color: "#48bb78"}} htmlFor="tab-show-single-one">Booked Riders</label>
                      <div className="tab-show-content overflow-hidden border-l-2 bg-gray-100 border-500 leading-normal">
                          { this.state.orderList.length > 0 && this.state.orderList.map((order, i) => {
                              return(
                                this.state.isAdmin || this.driver?.has_active_subscription || order.user?.has_active_subscription || order?.status === 1  ? 
                                  <ItemChoice label={`${order.user.first_name} ${order.user.last_name} - R${order.price.value * order.seats}(${order.seats})`} rightAddon={<Avatar image={phoneIcon}/>} href={`tel:${order.user.cellphone}`} key={i}  />
                                :
                                <ItemChoice label={`${order.user.first_name} - Pending Deposit`} rightAddon={<p className="fa fa-paper-plane-o text-green-500" />} href={`/subscription/driver`} key={i}  />
                              )
                          })
                        }

                        { !this.state.orderList.length ?
                         <p className="p-5">Riders will show here once they have joined your lift.</p> : '' }
                      </div>
                    </div>
                    :
                    ''
                }

                {(!this.state.isOwner && this.state.hasJoined) || this.state.isAdmin ?

                  <div className="tab-show w-full overflow-hidden border-t">
                    <input className="absolute opacity-0" id="tab-show-single-two" type="radio" name="tab-shows2" />
                    <label className="block p-5 leading-normal cursor-pointer" style={{fontSize: "30px", color: "#48bb78"}} htmlFor="tab-show-single-two">Contact Driver</label>
                    <div className="tab-show-content overflow-hidden border-l-2 bg-gray-100 border-500 leading-normal">
                    { this.state.isAdmin || this.state.currentUser?.has_active_subscription || this.state.driver?.has_active_subscription || this.state.order?.status === 1 ?
                      <ItemChoice label={this.state.driver.first_name + ' ' + this.state.driver.last_name} rightAddon={<Avatar image={phoneIcon}/>} href={`tel:${this.state.driver.cellphone}`}  />
                    :
                    <div class="flex gap-4 bg-red-100 p-4 rounded-md">
                        <div class="w-max">
                            <div class="h-10 w-10 flex rounded-full bg-gradient-to-b from-red-100 to-orange-300 text-orange-700">
                              <span class="material-icons material-icons-outlined m-auto" style={{fontSize: "20px"}}>gpp_bad</span>
                            </div>
                        </div>
                        <div class="space-y-1 text-sm">
                          <h6 class="font-medium text-orange-900">Payment Pending</h6>
                          <p class="text-orange-700 leading-tight">Liftsearch's core services are safety and reliability for drivers and passengers. The R50 deposit is your upfront commitment that the driver will find you when he comes to pick you up. You only pay the remaining balance to the driver.
                          <Link to={{
                            pathname: '/missed-payment',
                            state: {
                                order: this.state.order,
                                trip: this.state.trip
                            }
                            }}
                            className="text-green-500"
                    > Pay Now</Link> to contact driver.</p>
                        </div>
                    </div>
                    }
                    </div>
                  </div>
                  :
                  ''
                }
                </div>
              </div>
              <TheVoice>{this.state.trip.date} { this.state.trip.time.split('T')[1].substring(0, 5) }</TheVoice>
              </BaseSection>
              <BaseSection>
                <Itinerary places={[{
              mainLabel: this.state.departure.name,
              subLabel: this.state.departure.province,
              href: '#'
            }, {
              mainLabel: this.state.destination.name,
              subLabel: this.state.destination.province,
              href: '#'
            }]} small={false} />
              </BaseSection>
              <BaseSection noHorizontalSpacing>
                <ItemData data={this.state.priceList} mainInfo="Price per seat" />
                <ContentDivider />
                <ItemChoice label={this.state.driver.first_name + ' ' + this.state.driver.last_name} rightAddon={<Avatar image={this.state.trip.user.profile_pic_url || '//ssl.gstatic.com/accounts/ui/avatar_2x.png'}/>} href="#" />
                <ContentDivider />
              </BaseSection>
              <BaseSection noHorizontalSpacing>
                <ItemChoice label={this.state.vehicle.make + ' ' + this.state.vehicle.model} rightAddon={<Avatar image={this.state.vehicle.image_url || carpool } />} href="#" />
              </BaseSection>
              <BaseSection noHorizontalSpacing>
                <ContentDivider />
                <div className="payment">
                {!this.state.isOwner && !this.state.hasJoined ?
                  <Link to={{
                    pathname: '/payment',
                    state: {
                        trip_id: this.state.trip.id,
                        trip: this.state.trip,
                        departure: this.state.departure,
                        destination: this.state.destination,
                        driver: this.state.driver,
                        vehicle: this.state.vehicle,
                        seats: this.state.seats,
                        price: this.state.price
                    }
                    }}
                    style={{textDecoration: 'none', color: '#2DBEFF'}}
                    >
                      <ItemChoice label="Continue" href="#" style={{textDecoration: 'none', color: '#2DBEFF'}} />
                </Link>
                :
                ''
              }
                </div>
              </BaseSection>
            </div>
          }
      </div>

    );
  }
}
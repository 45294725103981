import React, { Component } from "react";
import vutivi from '../vutivi.jpeg'
import './home.css';
import Stats from "./misc/stats";

class About extends Component {

  componentDidMount(){
  }

  render() {
    return (
      <main className="mt-16 w-full flex flex-col items-center">
      <div className="w-screen mt-1 mb-6">
        <h2 className="text-3xl text-gray-800 text-center font-bold">
          The story of {" "}
          <span className="text-green-500">Liftsearch</span>
        </h2>
      </div>
      <div>
        <div className="bg-gray-100 lg:py-12 lg:flex lg:justify-center">
          <div className="bg-white lg:mx-12 lg:flex lg:max-w-5xl lg:shadow-lg lg:rounded-lg">
            <div className="lg:w-1/2 sm:w-full">
              <div
                className="h-64 bg-cover lg:rounded-lg lg:h-full md:w-full"
                style={{
                  backgroundImage:
                    `url(${vutivi})`
                }}
              />
            </div>
            <div className="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2 sm:w-full md:w-full">
              <h2 className="text-3xl text-gray-800 font-bold">
                Revolutionizing Travel and Delivery: The Rapid Rise of Liftsearch
              </h2>
              <p className="mt-4 text-gray-600">
                At Liftsearch, we're more than just a ridesharing and parcel delivery app; we're a transformative solution bridging the gap between provinces and communities.<br/><br/> 

                Founded by Vutivi Shivambu, a visionary software engineer, our journey began with a personal experience that highlighted a glaring need. Vutivi's poignant journey from Gauteng to a friend's funeral in a remote village highlighted the glaring issue of last mile transportation. Although he made it to the town, the lack of reliable transport options to reach the village at night led to a missed opportunity to pay his final respects.<br/><br/> 

                Safety and reliability are the cornerstones of Liftsearch. Our rigorous driver screening, insistence on reliability and secure payment systems guarantee peace of mind for every user. We believe in enabling safe journeys and seamless parcel deliveries, no matter the destination.<br/><br/> 

                A defining feature of Liftsearch is our dedication to remote villages. Our innovative integration of village-centric trip searches ensures that accessing these areas is no longer a challenge. We're committed to enhancing connectivity and creating opportunities for these communities.<br/><br/> 

                As we continue to grow, Liftsearch remains committed to our core values: accessibility, safety, reliability, and community empowerment. Join us in redefining the way we connect, travel, and deliver - because at Liftsearch, we're not just connecting people, we're connecting hearts and possibilities across provinces.<br/><br/>                
                <br/><br/> 
              </p>
              <div className="mt-8">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen mt-1 mb-6">
        <br/><br/>
        <div className="w-screen mt-1 mb-6">
          <h2 className="text-3xl text-gray-800 text-center font-bold">
            <span className="text-green-500">Liftsearch</span> In Numbers {" "}
          </h2>
        </div>
        <Stats />
      </div>
    </main>
    );
  }
}

export default About;
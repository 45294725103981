import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const length = value => {
  if (value.length < 8) {
    return (
      <div className="alert alert-danger" role="alert">
        Password must be greater than 8 characters!
      </div>
    );
  }
};


export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleReset = this.handleReset.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);

    this.state = {
      password: "",
      confirm_password: "",
      reset_token: this.props?.match?.params?.reset_token,
      loading: false,
      message: "",
      successful: false
    };
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChangeConfirmPassword(e) {
    this.setState({
      confirm_password: e.target.value
    });

  }

  confirmed = value => {
    if (value !== this.state.password) {
      return (
        <div className="alert alert-danger" role="alert">
          Passwords doesn't match!
        </div>
      );
    }
  };

  handleReset(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.ResetPassword(this.state.password, this.state.reset_token).then(
        res => {

          this.props.history.push({
            pathname: "/auth/login",
            state: { message: res.data.message,
                     successful: true }
          })

          // window.location.reload();

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            successful: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
            )}
          <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">
                Reset Password
              </h2>
          <Form
            onSubmit={this.handleReset}
            ref={c => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required, length]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="confirm_password">Confirm Password</label>
              <Input
                type="password"
                className="form-control"
                name="confirm_password"
                value={this.state.confirm_password}
                onChange={this.onChangeConfirmPassword}
                validations={[required, this.confirmed]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Reset</span>
              </button>
            </div>
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
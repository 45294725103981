import React, { Component } from "react";
import { TripCard } from '@blablacar/ui-library/build/tripCard';
import { WarningIcon } from '@blablacar/ui-library/build/icon/warningIcon';
import { Loader } from '@blablacar/ui-library/build/loader'

import TripService from "../services/trip.service";
import { ContentDivider } from "@blablacar/ui-library/build/divider/contentDivider";

export default class Trips extends Component {
  constructor(props) {
    super(props);
    this.state = {
        trips: [],
        loading: true
    }
  }

  componentDidMount(){
    TripService.my_trips().then(response => {
      this.setState({loading: false});
      this.setState({trips: response.data});
    });
  }

  render() {
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return (
      <div className="container">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Upcoming lifts
            </h2>
            <div className="mt-2 flex items-center text-sm text-gray-500">
                <i className="fas fa-plane-departure"></i>
                Upcoming lifts you have booked/offered.
            </div>
          </div>
        </div>
        <ContentDivider />

        {this.state.loading ? <Loader /> :

        this.state.trips.length > 0 ?  this.state.trips.filter(trip => {
                return (Date.parse(new Date(trip.date).toLocaleDateString("en-US", options)) > Date.parse(new Date().toLocaleDateString("en-US", options)))}
              ).map((item, i) => {

                let date = new Date(item.date).toLocaleDateString("en-US", options);
                let time = item.time.split('T')[1].substring(0, 5);
              return (

                <div><TripCard aria-label={`Pick-up point: ${item.departure.name}, Drop-off point: ${item.destination.name}, Departure time: ${time}, warning`} key={i} href={`trips/${item.id}`} itinerary={[{
                  mainLabel: item.departure.name,
                  subLabel: item.departure.province_id,
                  isoDate: date,
                }, {
                  mainLabel: item.destination.name,
                  subLabel: item.destination.province_id,
                }]} price={`R${item?.prices[item.prices.length - 1]?.value}`} flags={{
                  ladiesOnly: true,
                  aloneInTheBack: true,
                  maxTwo: true,
                  autoApproval: true
                }} titles={{
                  ladiesOnly: 'Ladies Only',
                  aloneInTheBack: 'Alone in the back',
                  maxTwo: 'Max 2 in the back',
                  autoApproval: 'Auto approval'
                }} metaUrl="Meta URL" statusInformation={{
                  icon: <WarningIcon />,
                  text: `${Date.parse(date) < Date.parse(new Date().toLocaleDateString("en-US", options)) ? 'Due today' : ''}`,
                  highlighted: false
                }} badge="Upcoming" title={`${date}, ${time}`} driver={{
                  avatarUrl: item?.user?.profile_pic_url || '//ssl.gstatic.com/accounts/ui/avatar_2x.png',
                  firstName: item?.user?.first_name,
                  rating: '4.9'
                }} /><div className="clear"></div></div>

              )

          }) :
          <p style={{color: 'red'}}>You have no upcoming lifts</p>
          }
          <br></br><br></br>
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Past lifts
              </h2>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                  <i className="fas fa-plane-departure"></i>
                  Past lifts you have booked/offered.
              </div>
            </div>
          </div>
          <ContentDivider />
          {this.state.loading ? <Loader /> :

            this.state.trips.length > 0 ?  this.state.trips.filter(trip => {
                    return (Date.parse(new Date(trip.date).toLocaleDateString("en-US", options))) < Date.parse(new Date().toLocaleDateString("en-US", options))
                  }
                  ).map((item, i) => {
                  let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                  let date = new Date(item.date).toLocaleDateString("en-US", options);
                  let time = item.time.split('T')[1].substring(0, 5);
                  return (

                    <div><TripCard aria-label={`Pick-up point: ${item.departure.name}, Drop-off point: ${item.destination.name}, Departure time: 09:00, warning`} key={i} href={`trips/${item.id}`} itinerary={[{
                      mainLabel: item.departure.name,
                      subLabel: item.departure.province_id,
                      isoDate: date,
                    }, {
                      mainLabel: item.destination.name,
                      subLabel: item.destination.province_id,
                    }]} price={`R${item?.prices[item.prices.length - 1]?.value}`} flags={{
                      ladiesOnly: true,
                      aloneInTheBack: true,
                      maxTwo: true,
                      autoApproval: true
                    }} titles={{
                      ladiesOnly: 'Ladies Only',
                      aloneInTheBack: 'Alone in the back',
                      maxTwo: 'Max 2 in the back',
                      autoApproval: 'Auto approval'
                    }} metaUrl="Meta URL" statusInformation={{
                      icon: <WarningIcon />,
                      text: 'Warning',
                      highlighted: false
                    }} badge="Completed" title={`${date}, ${time}`} driver={{
                      avatarUrl: item?.user?.profile_pic_url || '//ssl.gstatic.com/accounts/ui/avatar_2x.png',
                      firstName: item?.user?.first_name,
                      rating: '4.9'
                    }} /><div className="clear"></div></div>

                  )

              }) :
              <p style={{color: 'red'}}>You have no past lifts</p>
              }
        </div>
    );
  }
}


import React, { Component } from "react";
// import AuthService from "../services/auth.service";
// import UserService from "../services/user.service";
import { FocusVisibleProvider } from '@blablacar/ui-library/build/_utils/focusVisibleProvider';

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // currentUser: AuthService.getCurrentUser(),
    };
  }

  componentDidMount(){
    // this.setState({avatarURL: this.state.currentUser.user.profile_pic_url})
  }

  render() {
    // const { currentUser } = this.state;

    return (
          <FocusVisibleProvider>
            <div className="container font-sans flex flex-col items-center">
              <div className="container">
                <div role="presentation" className="sc-gqjmRU erfFfK items-center">

                  <div className="section-content section-content--noHorizontalSpacing">
                    <div className="text-center mb-xl mt-xl items-center">
                      <div className="sc-kjoXOD fZEPCa kirk-avatar--medium kirk-avatar--image inline-block kirk-avatar">
                        <img src="https://d2kwny77wxvuie.cloudfront.net/user/1DYBvNSMTuKMFK9UY5q4jw/thumbnail_144x144.jpeg" alt="Jorge's public profile" />
                        <span className="sc-bxivhb AUIoD kirk-badge kirk-avatar-badge--idCheck">
                          <span aria-hidden="false">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="kirk-icon kirk-icon-check sc-ifAKCX PRjYD sc-EHOje pSQTe" width="100%" height="100%" aria-hidden="true">
                              <path d="M6.5 12.5l4 4 8-8" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"></path>
                            </svg>
                          </span>
                        </span>
                      </div>
                      <h1 className="sc-iwsKbI sc-cHGsZl pAXwt my-l">Jorge</h1>
                      <div className="sc-gPEVay fsTkMx" role="presentation">
                        <p className="sc-iwsKbI sc-hMqMXs eljMPs">33 y/o</p>
                      </div>
                    </div>
                    <div className="kirk-item sc-gisBJw kWBvsh">
                      <span className="kirk-item-leftWrapper">
                        <span className="kirk-item-leftText">
                          <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body">Experience: Ambassador</span>
                        </span>
                      </span>
                    </div>
                    <div className="sc-gPEVay fsTkMx" role="presentation">
                      <p className="sc-iwsKbI sc-hMqMXs eljMPs">Traveller.</p>
                    </div>
                  </div>
              <div role="presentation" className="sc-uJMKN duCMpr">
                <div className="section-content section-content--noHorizontalSpacing">
                  <ul className="sc-caSCKo kehXzK kirk-items-list">
                    <li className="kirk-items-list-item">
                      <a type="button" className="kirk-item kirk-item--clickable sc-gisBJw kWBvsh kirk-item-choice" href="/user/show/7ef83e00-35f0-11e6-a000-002094391f3a/reviews">
                        <span className="kirk-item-leftWrapper">
                          <span className="kirk-item-leftAddon">
                            <svg viewBox="-2 -4 84 82" xmlns="http://www.w3.org/2000/svg" className="kirk-icon sc-ifAKCX cqcJrU" width="24" height="24" aria-hidden="true">
                              <g>
                                <linearGradient y2="0%" x2="100%" y1="0%" x1="0%" id="gradient-1">
                                  <stop stopColor="#708C91" offset="100%"></stop>
                                  <stop stopColor="#FFF" offset="0%"></stop>
                                </linearGradient>
                                <path stroke="#708C91" strokeWidth="4" fill="url(#gradient-1)" d="M 40.000 60.000 L 63.511 72.361 L 59.021 46.180 L 78.042 27.639 L 51.756 23.820 L 40.000 0.000 L 28.244 23.820 L 1.958 27.639 L 20.979 46.180 L 16.489 72.361 L 40.000 60.000"></path>
                              </g>
                            </svg>
                          </span>
                          <span className="kirk-item-leftText">
                            <span className="kirk-text kirk-text-title sc-cvbbAY iCzKER" style={{ color: "rgb(5, 71, 82)" }}>4.7/5 –  15 ratings </span>
                          </span>
                        </span>
                        <span className="kirk-item-rightAddon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="kirk-icon sc-ifAKCX cqcJrU" width="24" height="24" aria-hidden="true">
                            <polyline fill="none" stroke="#708C91" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </span>
                      </a>
                    </li>
                    <li className="kirk-items-list-item">
                      <div className="kirk-item sc-gisBJw kWBvsh">
                        <span className="kirk-item-leftWrapper">
                          <span className="kirk-item-leftText">
                            <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body">3/3 - Good driving skills</span>
                          </span>
                        </span>
                      </div>
                    </li>
                  </ul>
                  <div role="presentation" aria-hidden="true" className="sc-eXEjpC iRgCJz"></div>
                  <section className="" aria-label="Ride preferences">
                  <ul>
                    <li className="kirk-item sc-gisBJw kWBvsh">
                      <span className="kirk-item-leftWrapper">
                        <span className="kirk-item-leftAddon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="kirk-icon sc-ifAKCX dhPPZY" width="24" height="24" aria-hidden="true">
                            <path d="M7.036 7.486L6.541 5.82a2.24 2.24 0 114.292-1.276l.496 1.667a2.24 2.24 0 01-4.293 1.276zm3.334-.991l-.495-1.667a1.24 1.24 0 10-2.376.706l.496 1.667a1.24 1.24 0 002.375-.706zm5.635.706l.496-1.667a1.24 1.24 0 10-2.376-.706l-.495 1.667a1.24 1.24 0 002.375.706zm-3.334-.991l.496-1.667a2.24 2.24 0 114.292 1.276l-.495 1.667A2.24 2.24 0 0112.67 6.21zm-9.833 6.26l-.366-.428a2.24 2.24 0 013.404-2.91l.366.43a2.24 2.24 0 01-3.404 2.909zm2.644-2.259l-.366-.428a1.24 1.24 0 00-1.884 1.61l.366.428a1.24 1.24 0 101.884-1.61zm14.92 1.61l.366-.429a1.24 1.24 0 00-1.884-1.61l-.366.43a1.24 1.24 0 001.884 1.609zm-2.644-2.26l.366-.428a2.24 2.24 0 013.404 2.909l-.366.429a2.24 2.24 0 01-3.404-2.91zm-9.915 3.992l.02-.03c1.993-2.813 6.28-2.813 8.294.03l1.725 2.846c1.689 2.787-1.191 6.058-4.284 4.91l-.15-.056a4.172 4.172 0 00-2.896 0l-.15.056c-3.093 1.148-5.973-2.123-4.284-4.91l1.725-2.846zm-.87 3.364c-1.186 1.959.857 4.28 3.081 3.454l.15-.055a5.172 5.172 0 013.592 0l.15.055c2.224.826 4.267-1.495 3.08-3.454L15.323 14.1c-1.592-2.246-5.034-2.25-6.634-.014l-1.715 2.83z"></path>
                          </svg>
                        </span>
                        <span className="kirk-item-leftText">
                          <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body">I love pets. Woof!</span>
                        </span>
                      </span>
                    </li>
                    <li className="kirk-item sc-gisBJw kWBvsh">
                      <span className="kirk-item-leftWrapper">
                        <span className="kirk-item-leftAddon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="kirk-icon sc-ifAKCX dhPPZY" width="24" height="24" aria-hidden="true">
                            <path d="M23.5,1h-15C8.224,1,8,1.224,8,1.5v15.882C7.266,16.542,6.2,16,5,16c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4V8h14v7.382C22.267,14.542,21.2,14,20,14c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4V1.5C24,1.224,23.776,1,23.5,1zM5,23c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S6.654,23,5,23z M20,21c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S21.654,21,20,21z M9,7V2h14v5H9z"></path>
                          </svg>
                        </span>
                        <span className="kirk-item-leftText">
                          <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body">It’s all about the playlist!</span>
                        </span>
                      </span>
                    </li>
                    <li className="kirk-item sc-gisBJw kWBvsh">
                      <span className="kirk-item-leftWrapper">
                        <span className="kirk-item-leftAddon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="kirk-icon sc-ifAKCX cqcJrU" width="24" height="24" aria-hidden="true">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g transform="translate(0.000000, 2.000000)">
                                <path stroke="#708C91" strokeLinecap="round" strokeLinejoin="round" d="M20.7818182,15.4727273 C22.4545455,13.8 23.5,11.7090909 23.5,9.40909091 C23.5,4.18181818 18.3772727,0 12,0 C5.62272727,0 0.5,4.18181818 0.5,9.40909091 C0.5,14.6363636 5.62272727,18.8181818 12,18.8181818 C13.15,18.8181818 14.1954545,18.7136364 15.2409091,18.4 L21.4090909,20.9090909 L20.7818182,15.4727273 Z"></path>
                                <path fill="#708C91" d="M10.914,3.91600153 C11.246,3.96800153 11.482,4.18400153 11.482,4.60800153 C11.482,5.15600153 11.062,5.40000153 10.578,5.40000153 L9.394,5.40000153 L9.394,2.60000153 L10.502,2.60000153 C10.994,2.60000153 11.342,2.84000153 11.342,3.30400153 C11.342,3.60400153 11.194,3.82000153 10.914,3.90800153 L10.914,3.91600153 Z M10.422,3.07200153 L9.906,3.07200153 L9.906,3.74400153 L10.402,3.74400153 C10.662,3.74400153 10.806,3.62000153 10.806,3.40400153 C10.806,3.18400153 10.65,3.07200153 10.422,3.07200153 Z M10.49,4.92800153 C10.77,4.92800153 10.938,4.78800153 10.938,4.55600153 C10.938,4.32400153 10.778,4.18400153 10.47,4.18400153 L9.906,4.18400153 L9.906,4.92800153 L10.49,4.92800153 Z M12.33,2.60000153 L11.838,2.60000153 L11.838,5.40000153 L12.33,5.40000153 L12.33,2.60000153 Z M13.55,5.46000153 C13.066,5.46000153 12.662,5.10800153 12.662,4.47600153 C12.662,3.84400153 13.066,3.49200153 13.55,3.49200153 C13.85,3.49200153 14.05,3.62000153 14.166,3.81200153 L14.174,3.81200153 L14.174,3.55200153 L14.662,3.55200153 L14.662,5.40000153 L14.174,5.40000153 L14.174,5.14000153 L14.166,5.14000153 C14.05,5.33200153 13.85,5.46000153 13.55,5.46000153 Z M13.674,4.99600153 C13.97,4.99600153 14.174,4.78800153 14.174,4.47600153 C14.174,4.16400153 13.97,3.95600153 13.674,3.95600153 C13.378,3.95600153 13.174,4.16400153 13.174,4.47600153 C13.174,4.78800153 13.378,4.99600153 13.674,4.99600153 Z"></path>
                                <path fill="#708C91" d="M9.828,9.032 C10.492,9.136 10.964,9.568 10.964,10.416 C10.964,11.512 10.124,12 9.156,12 L6.788,12 L6.788,6.4 L9.004,6.4 C9.988,6.4 10.684,6.88 10.684,7.808 C10.684,8.408 10.388,8.84 9.828,9.016 L9.828,9.032 Z M8.844,7.344 L7.812,7.344 L7.812,8.688 L8.804,8.688 C9.324,8.688 9.612,8.44 9.612,8.008 C9.612,7.568 9.3,7.344 8.844,7.344 Z M8.98,11.056 C9.54,11.056 9.876,10.776 9.876,10.312 C9.876,9.848 9.556,9.568 8.94,9.568 L7.812,9.568 L7.812,11.056 L8.98,11.056 Z M12.66,6.4 L11.676,6.4 L11.676,12 L12.66,12 L12.66,6.4 Z M15.1,12.12 C14.132,12.12 13.324,11.416 13.324,10.152 C13.324,8.888 14.132,8.184 15.1,8.184 C15.7,8.184 16.1,8.44 16.332,8.824 L16.348,8.824 L16.348,8.304 L17.324,8.304 L17.324,12 L16.348,12 L16.348,11.48 L16.332,11.48 C16.1,11.864 15.7,12.12 15.1,12.12 Z M15.348,11.192 C15.94,11.192 16.348,10.776 16.348,10.152 C16.348,9.528 15.94,9.112 15.348,9.112 C14.756,9.112 14.348,9.528 14.348,10.152 C14.348,10.776 14.756,11.192 15.348,11.192 Z"></path>
                                <path fill="#708C91" d="M10.914,14.2159969 C11.246,14.2679969 11.482,14.4839969 11.482,14.9079969 C11.482,15.4559969 11.062,15.6999969 10.578,15.6999969 L9.394,15.6999969 L9.394,12.8999969 L10.502,12.8999969 C10.994,12.8999969 11.342,13.1399969 11.342,13.6039969 C11.342,13.9039969 11.194,14.1199969 10.914,14.2079969 L10.914,14.2159969 Z M10.422,13.3719969 L9.906,13.3719969 L9.906,14.0439969 L10.402,14.0439969 C10.662,14.0439969 10.806,13.9199969 10.806,13.7039969 C10.806,13.4839969 10.65,13.3719969 10.422,13.3719969 Z M10.49,15.2279969 C10.77,15.2279969 10.938,15.0879969 10.938,14.8559969 C10.938,14.6239969 10.778,14.4839969 10.47,14.4839969 L9.906,14.4839969 L9.906,15.2279969 L10.49,15.2279969 Z M12.33,12.8999969 L11.838,12.8999969 L11.838,15.6999969 L12.33,15.6999969 L12.33,12.8999969 Z M13.55,15.7599969 C13.066,15.7599969 12.662,15.4079969 12.662,14.7759969 C12.662,14.1439969 13.066,13.7919969 13.55,13.7919969 C13.85,13.7919969 14.05,13.9199969 14.166,14.1119969 L14.174,14.1119969 L14.174,13.8519969 L14.662,13.8519969 L14.662,15.6999969 L14.174,15.6999969 L14.174,15.4399969 L14.166,15.4399969 C14.05,15.6319969 13.85,15.7599969 13.55,15.7599969 Z M13.674,15.2959969 C13.97,15.2959969 14.174,15.0879969 14.174,14.7759969 C14.174,14.4639969 13.97,14.2559969 13.674,14.2559969 C13.378,14.2559969 13.174,14.4639969 13.174,14.7759969 C13.174,15.0879969 13.378,15.2959969 13.674,15.2959969 Z"></path>
                              </g>
                              <g transform="translate(-100.000000, -20.000000)"></g>
                            </g>
                          </svg>
                        </span>
                        <span className="kirk-item-leftText">
                          <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body">I’m a chatterbox!</span>
                        </span>
                      </span>
                    </li>
                    <li className="kirk-item sc-gisBJw kWBvsh">
                      <span className="kirk-item-leftWrapper">
                        <span className="kirk-item-leftAddon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="kirk-icon sc-ifAKCX dhPPZY" width="24" height="24" aria-hidden="true">
                            <g>
                              <path d="M22.558,17.817L8.371,9.626C7.908,9.359,7.27,9.532,7.004,9.992l-1.518,2.629c-0.275,0.477-0.111,1.09,0.366,1.366l14.188,8.191c0.151,0.087,0.324,0.133,0.498,0.133c0.356,0,0.688-0.19,0.867-0.499l1.518-2.629C23.198,18.706,23.035,18.094,22.558,17.817z M20.54,21.312C20.54,21.312,20.539,21.312,20.54,21.312l-14.188-8.19l1.518-2.629l14.187,8.191L20.54,21.312z"></path>
                              <path d="M4.237,5.03C4.471,5.175,4.78,5.103,4.925,4.868l1.14-1.843c1.002-1.619,3.079-2.142,4.727-1.19l5.47,3.158c0.693,0.4,1.463,0.605,2.242,0.605c0.391,0,0.784-0.052,1.172-0.155c1.161-0.312,2.132-1.056,2.731-2.097l0.234-0.406c0.139-0.239,0.057-0.545-0.183-0.683c-0.238-0.136-0.545-0.057-0.683,0.183l-0.235,0.406c-0.467,0.81-1.221,1.389-2.124,1.63c-0.904,0.243-1.846,0.118-2.655-0.35l-5.47-3.158c-2.121-1.225-4.79-0.552-6.078,1.53l-1.14,1.843C3.93,4.576,4.002,4.884,4.237,5.03z"></path>
                              <path d="M10.52,3.568C9.325,2.878,7.794,3.289,7.105,4.483L6.611,5.338C6.473,5.577,6.555,5.883,6.794,6.021c0.237,0.138,0.544,0.056,0.683-0.183l0.494-0.854c0.2-0.347,0.524-0.595,0.911-0.699c0.388-0.104,0.791-0.05,1.138,0.15l5.152,2.975c0.079,0.045,0.165,0.067,0.25,0.067c0.172,0,0.341-0.089,0.433-0.25c0.139-0.239,0.057-0.545-0.183-0.683L10.52,3.568z"></path>
                              <path d="M3.183,6.727C2.944,6.588,2.638,6.67,2.5,6.91l-2,3.464c-0.138,0.239-0.056,0.545,0.183,0.683c0.079,0.045,0.165,0.067,0.25,0.067c0.173,0,0.341-0.089,0.434-0.25l2-3.464C3.504,7.171,3.422,6.865,3.183,6.727z"></path>
                              <path d="M5.183,8.067C4.944,7.929,4.638,8.01,4.5,8.25l-2,3.464c-0.138,0.239-0.056,0.545,0.183,0.683c0.079,0.046,0.165,0.067,0.25,0.067c0.173,0,0.341-0.09,0.434-0.25l2-3.464C5.504,8.511,5.422,8.205,5.183,8.067z"></path>
                            </g>
                          </svg>
                        </span>
                        <span className="kirk-item-leftText">
                          <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body">I’m fine with smoking</span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  </section>
                  <div className="sc-eqIVtm sc-fAjcbJ KIyVX"><hr /></div>
                  <ul className="sc-caSCKo kehXzK kirk-items-list">
                  <li className="kirk-items-list-item">
                    <div className="kirk-item sc-gisBJw kWBvsh">
                      <span className="kirk-item-leftWrapper"><span className="kirk-item-leftAddon">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="kirk-icon kirk-icon-check sc-ifAKCX hWOFqI sc-EHOje jElzVE" width="24" height="24" aria-hidden="true">
                          <path d="M6.5 12.5l4 4 8-8" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"></path>
                        </svg>
                      </span>
                      <span className="kirk-item-leftText">
                        <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body">ID verified</span>
                      </span>
                      </span>
                    </div>
                  </li>
                  <li className="kirk-items-list-item">
                    <div className="kirk-item sc-gisBJw kWBvsh">
                      <span className="kirk-item-leftWrapper">
                        <span className="kirk-item-leftAddon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="kirk-icon kirk-icon-check sc-ifAKCX hWOFqI sc-EHOje jElzVE" width="24" height="24" aria-hidden="true">
                            <path d="M6.5 12.5l4 4 8-8" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"></path>
                          </svg>
                        </span>
                        <span className="kirk-item-leftText">
                          <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body">Phone verified</span>
                        </span>
                      </span>
                    </div>
                  </li>
                  <li className="kirk-items-list-item">
                    <div className="kirk-item sc-gisBJw kWBvsh">
                      <span className="kirk-item-leftWrapper">
                        <span className="kirk-item-leftAddon">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="kirk-icon kirk-icon-check sc-ifAKCX hWOFqI sc-EHOje jElzVE" width="24" height="24" aria-hidden="true">
                            <path d="M6.5 12.5l4 4 8-8" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"></path>
                          </svg>
                        </span>
                        <span className="kirk-item-leftText">
                          <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body">Email verified</span>
                        </span>
                      </span>
                    </div>
                  </li>
                </ul>
                <div className="sc-eqIVtm sc-fAjcbJ KIyVX"><hr /></div>
                <ul className="sc-caSCKo kehXzK kirk-items-list">
                  <li className="kirk-items-list-item">
                    <div className="kirk-item sc-gisBJw kWBvsh">
                      <span className="kirk-item-leftWrapper">
                        <span className="kirk-item-leftText">
                          <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body"> 61 rides published </span>
                        </span>
                      </span>
                    </div>
                  </li>
                  <li className="kirk-items-list-item">
                    <div className="kirk-item sc-gisBJw kWBvsh">
                      <span className="kirk-item-leftWrapper">
                        <span className="kirk-item-leftText">
                          <span className="kirk-text kirk-text-body sc-cvbbAY iCzKER kirk-item-body">Member since June 2016</span>
                        </span>
                      </span>
                    </div>
                  </li>
                </ul>
                <a className="kirk-item kirk-item--highlighted kirk-item--clickable sc-gisBJw kWBvsh" href="/report/7ef83e00-35f0-11e6-a000-002094391f3a/1">
                  <span className="kirk-item-leftWrapper">
                    <span className="kirk-item-leftText">
                      <span className="kirk-text kirk-text-title sc-cvbbAY iCzKER">Report this member</span>
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          </div>
          </div>
        </FocusVisibleProvider>

    );
  }
}
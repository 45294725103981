import React, { Component } from "react";
import { BaseSection } from "@blablacar/ui-library/build/layout/section/baseSection";
import { TabsSection } from '@blablacar/ui-library/build/layout/section/tabsSection';
import { Avatar } from '@blablacar/ui-library/build/avatar';
import { ItemChoice } from "@blablacar/ui-library/build/itemChoice";
import { ItemEditableInfo } from '@blablacar/ui-library/build/itemEditableInfo';
import { ItemAction } from '@blablacar/ui-library/build/itemAction'
import { FocusVisibleProvider } from '@blablacar/ui-library/build/_utils/focusVisibleProvider';
import { PlusIcon } from "@blablacar/ui-library/build/icon/plusIcon";
import { ButtonGroup } from '@blablacar/ui-library/build/buttonGroup';
import { Button } from '@blablacar/ui-library/build/button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import firebase from 'firebase/app';
import 'firebase/storage'; 
import FileUploader from "react-firebase-file-uploader";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.handlePatchUser = this.handlePatchUser.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser().user,
      avatar: "",
      isUploading: false,
      progress: 0,
      avatarURL: "",
      password: "",
      loading: false,
      successful: this.props.location?.state?.successful,
      message: this.props.location?.state?.message
    };
  }

  componentDidMount(){
    this.setState({avatarURL: this.state.currentUser.profile_pic_url})
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }
  

  handlePatchUser(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      UserService.update(this.state.currentUser.id, {
        password: this.state.password
      }).then(
        (res) => {
          this.setState({
            loading: false,
            successful: false,
            message: res.message
          })
        },
        error => {

          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          
          this.setState({
            loading: false,
            successful: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }
  handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ avatarURL: url });

        UserService.update(this.state.currentUser.id, {
          profile_pic_url: this.state.avatarURL
        }).then(() => {
          window.location.reload();
        })
      });

      toast('Picture succesfully uploaded.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  };

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  copyCodeToClipboard = (content) => {
    navigator.clipboard.writeText(content)

    toast('Succesfully copied link.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  render() {
    const { currentUser } = this.state;

    return (
      <div className="container">
      <br></br>  
        <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
              {/* Same as */}
              <ToastContainer />
 
        <TabsSection tabsProps={{
          activeTabId: 'about',
          tabs: [{
            id: 'about',
            label: 'About You',
            panelContent: 
            <BaseSection>
              <br/>
          
              <ButtonGroup isInline>
                <Button href="/trips/new">Offer a lift</Button>
                <Button href="/" className="kirk-button-secondary">Find a lift</Button>
              </ButtonGroup>            
              <br/>
              <ItemChoice label={currentUser.first_name + ' ' + currentUser.last_name} rightAddon={<Avatar image={this.state.avatarURL || '//ssl.gstatic.com/accounts/ui/avatar_2x.png'} />} href="#" />
              <ItemChoice leftAddon={<PlusIcon></PlusIcon>} 
                label={"Add profile picture"}  
                rightAddon={
                <label style={{backgroundColor: '#e2e8f0', color: 'white', padding: 10, borderRadius: 4, cursor: 'pointer'}}> Upload
                <FileUploader
                  hidden
                  accept="image/*"
                  ref="fileUploader"
                  name="avatar"
                  randomizeFilename
                  storageRef={firebase.storage().ref("images")}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                  onProgress={this.handleProgress}
              /></label>} />
              <FocusVisibleProvider>
                <ItemEditableInfo label="Cell number (readonly)" value={this.state.currentUser.cellphone} readonly />
              </FocusVisibleProvider>
              <FocusVisibleProvider>
                <ItemEditableInfo label="Email (readonly)" value={this.state.currentUser.email} readonly />
              </FocusVisibleProvider>     
              <FocusVisibleProvider>
                <ItemEditableInfo label="Gender (readonly)" value={this.state.currentUser.gender} readonly />
              </FocusVisibleProvider>
              <FocusVisibleProvider>
              <label style={{backgroundColor: '#e2e8f0', padding: 10, borderRadius: 4, cursor: 'pointer'}}>Invite friends to Liftsearch</label>
                <ItemAction
                  labelTitle="Primary info"
                  subLabel={`https://liftsearch.co.za/auth/sign-up?referral_code=${this.state.currentUser.user?.referral_code}`}
                  action="Click to copy link"
                  onClick={() => this.copyCodeToClipboard(`https://liftsearch.co.za/auth/sign-up?referral_code=${this.state.currentUser.user?.referral_code}`)}
                />
                <br />
                Or via:
                <br />
                <ButtonGroup isInline>
                  <Button className="kirk-button-secondary" href={`https://api.whatsapp.com/send/?phone&text=Hi! You should check out Liftsearch, they’ve got top class safe and reliable lifts/riders for people travelling long distance in the same direction(Limpopo, Mpumalanga, Gauteng). Just sign up with this link: https://liftsearch.co.za/auth/sign-up?referral_code=${this.state.currentUser.user?.referral_code}&app_absent=0`}>Whatsapp</Button>
                  <Button href={`https://twitter.com/intent/tweet/?text=Liftsearch is the best and quickest way to get top class safe and reliable lifts/riders for people travelling in the same direction in South Africa. Just sign up with this link: https://liftsearch.co.za/auth/sign-up?referral_code=${this.state.currentUser.user?.referral_code}&app_absent=0`}>Twitter</Button>
                </ButtonGroup>  
              </FocusVisibleProvider>
            </BaseSection>,
            badgeContent: ''
          },
          {
            id: 'subscriptions',
            label: 'Subscriptions',
            panelContent: 
            <BaseSection noHorizontalSpacing>
              <br/>            
              <br/> 
              <FocusVisibleProvider>
              <label style={{backgroundColor: '#e2e8f0', padding: 10, borderRadius: 4, cursor: 'pointer'}}>{this.state.currentUser?.has_active_subscription ? "You have an active subscription" : "You have no active subscription"}</label>
              <br />
                Click Below to see benefits of subscribing to Liftsearch:
              <br />
              <ButtonGroup isInline>
                <Button className="kirk-button-secondary" href={`/subscription/driver`}>For Drivers</Button>
                <Button href={`/subscription/passenger`}>For Passengers</Button>
              </ButtonGroup>  
              </FocusVisibleProvider>
            </BaseSection>,
            badgeContent: ''
          }, {
            id: 'account',
            label: 'Account',
            panelContent: 
            <BaseSection noHorizontalSpacing>
              <br/>            
              <br/> 
              {this.state.message && (
                    <div className="form-group">
                      <div
                        className={
                          this.state.successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {this.state.message}
                      </div>
                    </div>
              )}
              <Form
                  onSubmit={this.handlePatchUser}
                  ref={c => {
                    this.form = c;
                  }}
                >

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={this.state.loading}
                    >
                      {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Update</span>
                    </button>
                  </div>
                  <CheckButton
                    style={{ display: "none" }}
                    ref={c => {
                      this.checkBtn = c;
                    }}
                  />
              </Form>
            </BaseSection>,
            badgeContent: ''
          }]
        }} />
      </div>
    );
  }
}
import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class VerifyNumber extends Component {
  constructor(props) {
    super(props);
    this.handleVerify = this.handleVerify.bind(this);
    this.onChangeVerificationNumber = this.onChangeVerificationNumber.bind(this);

    this.state = {
      verification_number: "",
      loading: false,
      message: this.props.location.state.message,
      successful: this.props.location.state.successful
    };
  }

  onChangeVerificationNumber(e) {
    this.setState({
      verification_number: e.target.value
    });
  }

  handleVerify(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.verifyNumber(this.state.verification_number).then(
        res => {

          this.props.history.push({
            pathname: "/auth/login",
            state: { message: res.data.message,
                     successful: true }
          })

          // window.location.reload();

        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            successful: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
            )}
          <h2 className="mt-3 text-center text-3xl font-extrabold text-gray-900">
                Verify your Cellphone Number
              </h2>
          <Form
            onSubmit={this.handleVerify}
            ref={c => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="verification_number">Verification Number</label>
              <Input
                type="text"
                className="form-control"
                name="verification_number"
                value={this.state.verification_number}
                onChange={this.onChangeVerificationNumber}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Verify</span>
              </button>
            </div>
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import VehicleService from "../services/vehicle.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class Vehicle extends Component {
    constructor(props) {
        super(props);
        this.handleCreateVehicle = this.handleCreateVehicle.bind(this);
        this.onChangeMake = this.onChangeMake.bind(this);
        this.onChangeModel = this.onChangeModel.bind(this);
        this.onChangePlateNumber = this.onChangePlateNumber.bind(this);
        this.onChangeColor = this.onChangeColor.bind(this);
        this.onChangeImageUrl = this.onChangeImageUrl.bind(this);

        this.state = {
            owner_id: AuthService.getCurrentUser().user.id,
            make: "",
            model: "",
            number_plate: "",
            color: "",
            image_url: "",
            successful: false,
            message: ""
        };
    }

    onChangeMake(e) {
        this.setState({
            make: e.target.value
        });
    }

    onChangeModel(e) {
        this.setState({
            model: e.target.value
        });
    }

    onChangePlateNumber(e) {
        this.setState({
            number_plate: e.target.value
        });
    }

    onChangeColor(e) {
        this.setState({
            color: e.target.value
        });
    }

    onChangeImageUrl(e) {
        this.setState({
            image_url: e.target.value
        });
    }

    componentDidMount(){
    }

    handleCreateVehicle(e) {
        e.preventDefault();

        this.setState({
          message: "",
          successful: false
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
          VehicleService.create(
            this.state.owner_id,
            this.state.make,
            this.state.model,
            this.state.number_plate,
            this.state.color,
            this.state.image_url
          ).then(
            response => {
              this.setState({
                message: response.message,
                successful: true
              });
              toast('Vehicle succesfully created. Create another one or navigate to do something else.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.errors) ||
                error.message ||
                error.toString();

              this.setState({
                successful: false,
                message: resMessage
              });
            }
          );
        }
    }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
            <p className="h4 text-center py-4">Create a vehicle</p>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
              {/* Same as */}
              <ToastContainer />
          <Form
            onSubmit={this.handleCreateVehicle}
            ref={c => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="make">Make</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="make"
                    value={this.state.make}
                    onChange={this.onChangeMake}
                    validations={[required]}
                  />
                </div>
                

                <div className="form-group">
                  <label htmlFor="model">Model</label>
                  <Input 
                    name='model' 
                    validations={[required]} 
                    type="text" 
                    className="form-control"
                    value={this.state.model}
                    onChange={this.onChangeModel}
                  /> 
                </div>

                <div className="form-group">
                  <label htmlFor="number_plate">Plate number</label>
                  <Input name='number_plate' 
                    validations={[required]}  
                    className="form-control"
                    type="text" 
                    value={this.state.number_plate}
                    onChange={this.onChangePlateNumber}
                  /> 
                </div>

                <div className="form-group">
                  <label htmlFor="color">Color</label>
                  <Input name='color' 
                    validations={[required]}  
                    className="form-control"
                    value={this.state.color}
                    type="text" 
                    onChange={this.onChangeColor}
                  /> 
                </div>

                <div className="form-group">
                  <label htmlFor="image_url">Image Url</label>
                  <Input name='image_url' 
                    validations={[required]}  
                    className="form-control"
                    value={this.state.image_url}
                    type="text" 
                    onChange={this.onChangeImageUrl}
                  /> 
                </div>

                <div className="form-group">
                  <button className="btn btn-primary btn-block">Create Vehicle</button>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}